import {
  Button,
  defaultTheme,
  Flex,
  Heading,
  Provider,
  View,
  DialogTrigger,
  Text,
} from "@adobe/react-spectrum";
import {
  error as ErrorToast,
  success as SuccessToast,
} from "@react/react-spectrum/Toast";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { workflowApi } from "../../../../api/workflowApi";
import Stepper from "../../../../components/Common/Stepper/Stepper";
import { DRAFT, PENDING } from "../../../../constants/Status";
import LoadingDialog from "../../../../components/Dialog/LoadingDialog";
import Tree from "../../../../components/Common/Tree/Tree";
import CommentsDialog from "../../../../components/Dialog/CommentsDialog";
import useUserProfile from "../../../../context/user-context";

const reviewSteps = [
  { step: 0, title: "Started" },
  { step: 1, title: "Approved" },
];

// const reviewSteps = [
//   { step: 0, title: "Started" },
//   { step: 1, title: "Review in progress" },
//   { step: 2, title: "Reviewed" },
// ];
// const approvalSteps = [
//   { step: 1, title: "Started" },
//   { step: 2, title: "Review in progress" },
//   { step: 3, title: "Reviewed" },
//   { step: 4, title: "Approval in progress" },
//   { step: 5, title: "Approved/Rejected" },
// ];
const approvalSteps = [
  // for draft status
  { step: 0, title: "Started" },
  { step: 1, title: "Draft in progress" },
  { step: 2, title: "Changes finalized" },
];

const stepsMap = {
  // NEW: 1,
  DRAFT: 1,
  INPROGRESS: 2,
  PENDINGFORAPPROVAL: 4,
  APPROVED: approvalSteps.length,
  REJECTED: approvalSteps.length,
  AUTOAPPROVED: approvalSteps.length - 1,
  CHANGE_NOTIFICATION: 2,
};

export default function HierarchyWorkflow({ match }) {
  const location = useLocation();
  const history = useHistory();
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  const { row, isReadOnly } = location.state ? location.state : {};
  const typeOfWorkflow = row.status;
  const typeOfHierarchy = row.wf_type;

  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState(reviewSteps);
  const [message, setMessage] = useState("");
  const [currentVersion, setCurrentVersion] = useState([]);
  const [proposedVersion, setProposedVersion] = useState([]);
  const [reviewedChanges, setReviewedChanges] = useState({});
  const [wfStatus, setWfStatus] = useState("");
  const [modifiedNodes, setModifiedNodes] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false);

  // useEffect(() => {
  //   if (!location.state) {
  //     if (location.pathname.indexOf(WorkflowHierarchyManagementPath) > -1) {
  //       history.push(WorkflowHierarchyManagementPath);
  //     } else {
  //       history.push(WorkflowHierarchyManagementPath);
  //     }
  //   }
  // }, [location]);

  const checkStatusAndSetActiveStep = (workflowStatus) => {
    const stepKeys = Object.keys(stepsMap);
    // if (workflowStatus === "VIEW ONLY") setActiveStep(3);
    if (stepKeys.indexOf(workflowStatus) !== -1) {
      setActiveStep(stepsMap[workflowStatus]);
    } else {
      // if (location.pathname.indexOf(WorkflowHierarchyManagementPath) > -1) {
      //   history.push(WorkflowHierarchyManagementPath);
      // }
      // history.push(WorkflowHierarchyManagementPath);
    }
    if (workflowStatus === "VIEW ONLY") setActiveStep(2);
  };

  /* eslint no-param-reassign: "error" */
  const traverseForModifiedNodes = (treeNode, nodeList) => {
    /* eslint no-underscore-dangle: 0 */
    if (nodeList.includes(treeNode._id)) {
      treeNode.modified = true;
      treeNode.proposedModified = true;
      treeNode.expanded = true;
    }
    if (treeNode.children.length === 0) {
      return;
    }
    /* eslint-disable no-console */
    /* eslint-disable no-restricted-syntax */
    for (const child of treeNode.children) {
      traverseForModifiedNodes(child, nodeList);
    }
  };

  function traverse(element, nextChange) {
    element?.children?.forEach((child) => {
      if (traverse(child, nextChange)) {
        element.isChildModified = true;
        element.expanded = true;
        if (child.modified) {
          nextChange.add(element._id);
        }
        if (child.isAdd || child.isEdit || child.isDelete) {
          nextChange.add(child._id);
        }
      }
    });

    return (
      element &&
      (element.modified ||
        element.isAdd ||
        element.isEdit ||
        element.isChildModified ||
        element.isDelete)
    );
  }

  function traverseCurrentVersion(element, nextChange) {
    element?.children?.forEach((child) => {
      if (traverseCurrentVersion(child, nextChange)) {
        if (child.modified) {
          nextChange.add(element._id);
        }
        if (child.proposedModified) {
          nextChange.add(element._id);
          nextChange.add(child._id);
          element.isChildModified = true;
          element.expanded = true;
        }
      }
    });

    return element && (element.modified || element.proposedModified);
  }

  const traverseTreeData = (treeData, isProposedVersion) => {
    const temp = treeData;
    const nextChange = new Set();
    temp.forEach((element) => {
      if (isProposedVersion) {
        traverse(element, nextChange);
        nextChange.delete(element._id);
      } else {
        traverseCurrentVersion(element, nextChange);
        nextChange.delete(element._id);
      }
    });

    if (isProposedVersion) setModifiedNodes(Array.from(nextChange));
    return temp;
  };

  useEffect(() => {
    if (match.params.wfId) {
      setIsPageLoading(true);
      if (typeOfWorkflow === DRAFT) {
        setSteps(approvalSteps);
      }
      workflowApi
        // .fetchHMSWorkflowDetails(match?.params?.wfId, authState.accessToken)
        .fetchWorkflowDetails(match?.params?.wfId, authState.accessToken)
        .then((data) => {
          setIsPageLoading(false);
          setCurrentVersion([]);
          setProposedVersion([]);
          if (data.from_state && !(Object.keys(data.from_state).length === 0)) {
            const currentVersionData = data.from_state;
            currentVersionData.expanded = true;
            // currentVersion.push(currentVersionData);
            // setCurrentVersion(currentVersionData);
            currentVersion.push(data.from_state);
          } else setCurrentVersion([]);

          if (data.to_state && !(Object.keys(data.to_state).length === 0)) {
            proposedVersion.push(data.to_state);
          } else setProposedVersion([]);

          setReviewedChanges({
            hierarchyType: typeOfHierarchy,
            wfId: data.wfId,
            removeNodeIds: [],
            treeData: [data.to_state],
          });
          // setProposedVersion(traverseTreeData(proposedVersion, true));
          setProposedVersion(traverseTreeData([data.to_state], true));
          // checkStatusAndSetActiveStep(data?.wfStatus?.toUpperCase());
          // setWfStatus(data?.wfStatus?.toUpperCase());
          // checkStatusAndSetActiveStep(row.status.toUpperCase());
          setWfStatus(row.status.toUpperCase());
          checkStatusAndSetActiveStep(row.status.toUpperCase());
        })
        .catch((error) => {
          setIsPageLoading(false);
          console.log(error);
          ErrorToast(
            error.response?.data?.message ||
              "Failed to get Current and Proposed Hierarchy!!",
            {
              timeout: 2000,
            }
          );
        });
    }
  }, [user]);

  const modifyCurrentTree = () => {
    const treeDataTemp = currentVersion;
    for (const root of treeDataTemp) {
      traverseForModifiedNodes(root, modifiedNodes);
    }
    setCurrentVersion(treeDataTemp);
  };

  // for expanding current version
  useEffect(() => {
    modifyCurrentTree();
    setCurrentVersion(traverseTreeData(currentVersion, false));
  }, [modifiedNodes]);

  const handleReviewedChanges = (removeNodeIds, treeData) => {
    reviewedChanges.treeData = treeData;
    reviewedChanges.removeNodeIds = removeNodeIds;
  };

  const callWorkflowApi = (
    action,
    wfId,
    stepMessage,
    successMessage,
    errorMessage
  ) => {
    reviewedChanges.action = action;
    reviewedChanges.userId = user?.userId;
    workflowApi
      .saveReviewedChanges(wfId, reviewedChanges, authState.accessToken)
      .then((status) => {
        if (status === 200) {
          setMessage(stepMessage);
          setActiveStep(steps.length);
          SuccessToast(successMessage, {
            timeout: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorToast(error.response?.data?.message || errorMessage, {
          timeout: 2000,
        });
      });
  };

  const handleSendForApproval = () => {
    reviewedChanges.action = "sendforapproval";
    reviewedChanges.userId = user?.userId;
    workflowApi
      .saveReviewedChanges(
        match?.params?.wfId,
        reviewedChanges,
        authState.accessToken
      )
      .then((status) => {
        if (status === 200) {
          setMessage(
            `All changes of workflow ${match.params.wfId} have been reviewed and sent for approval.`
          );
          setActiveStep(steps.length);
          SuccessToast("Changes send for approval!", {
            timeout: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorToast(
          error.response?.data?.message ||
            "Could not send the changes for approval. Kindly try again!!",
          {
            timeout: 2000,
          }
        );
      });
  };

  const handleSaveAsDraft = () => {
    reviewedChanges.action = "saveasdraft";
    reviewedChanges.userId = user?.userId;
    workflowApi
      .saveReviewedChanges(
        match?.params?.wfId,
        reviewedChanges,
        authState.accessToken
      )
      .then((status) => {
        if (status === 200) {
          if (activeStep === 0) {
            setActiveStep((prevStep) => prevStep + 1);
          }
          SuccessToast("Changes saved successfully!", {
            timeout: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorToast(
          error.response?.data?.message ||
            "Could not save the changes as draft. Kindly try again!!",
          {
            timeout: 2000,
          }
        );
      });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleApprove = () => {
    callWorkflowApi(
      "approve",
      match.params.wfId,
      `Workflow ${match.params.wfId} has been approved.`,
      "Proposed changes has been approved!",
      "Sorry something went wrong, please try again!!"
    );
  };

  const handleRequestChange = (comments) => {
    reviewedChanges.comments = comments;
    callWorkflowApi(
      "requestchange",
      match.params.wfId,
      `Workflow ${match.params.wfId} has been requested for changes.`,
      "Workflow has been requested for change!",
      "Sorry something went wrong, please try again!!"
    );
  };

  const handleReject = (comments) => {
    reviewedChanges.comments = comments;
    callWorkflowApi(
      "reject",
      match.params.wfId,
      `Workflow ${match.params.wfId} has been rejected.`,
      "Proposed changes has been rejected!",
      "Sorry something went wrong, please try again!!"
    );
  };

  const getNextIndex = (index, length, direction) => {
    const newIndex = index || 0;
    switch (direction) {
      case "next":
        return (newIndex + 1) % length;
      case "prev":
        return (newIndex === 0 && length - 1) || newIndex - 1;
      default:
        return newIndex;
    }
  };

  const handleNextChange = (direction) => {
    let index;
    if (
      (offset === null && direction === "next") ||
      modifiedNodes.length === 1
    ) {
      index = 0;
    } else {
      index = getNextIndex(offset, modifiedNodes.length, direction);
    }
    setOffset(index);
    setSearchString(modifiedNodes[index]);
  };

  return (
    <Provider theme={defaultTheme} colorScheme="light">
      <LoadingDialog isOpen={isPageLoading} />
      <div style={{ marginTop: "30px" }}>
        <Stepper items={steps} activeItemStep={activeStep} />
      </div>

      {activeStep === steps.length &&
      wfStatus !== "APPROVED" &&
      wfStatus !== "REJECTED" &&
      wfStatus !== "VIEW ONLY" ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          marginTop="size-200"
          gap="size-200"
          direction="column"
        >
          <Heading level={1}>{message}</Heading>
          <Button variant="cta" onPress={handleCancel}>
            Finish
          </Button>
        </Flex>
      ) : (
        <>
          <Flex>
            <View
              padding="size-80"
              marginStart="5.5rem"
              flex="1"
              UNSAFE_style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "inherit",
              }}
            >
              <Heading level={3}>
                Workflow Id:{" "}
                <Text UNSAFE_style={{ fontWeight: "normal" }}>
                  {match.params.wfId}
                </Text>
              </Heading>
              <Heading level={3}>
                Type:{" "}
                <Text UNSAFE_style={{ fontWeight: "normal" }}>
                  {typeOfHierarchy}
                </Text>
              </Heading>
            </View>
            <View
              marginEnd="5.5rem"
              UNSAFE_style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "right",
                alignItems: "end",
              }}
            >
              <Heading level={3}>
                Status:{" "}
                <Text UNSAFE_style={{ fontWeight: "normal" }}>{wfStatus}</Text>
              </Heading>
              <Heading level={3}>
                Requested By:{" "}
                <Text UNSAFE_style={{ fontWeight: "normal" }}>
                  {row.created_by}
                </Text>
              </Heading>
            </View>
          </Flex>
          <Flex gap="size-800" justifyContent="center" marginTop="size-50">
            <Tree
              heading="Previous Hierarchy"
              isReadOnly
              data={currentVersion}
              hierarchyType={reviewedChanges?.hierarchyType}
              workflow={typeOfWorkflow}
              nextNode={searchString}
              onNextChange={handleNextChange}
              modifiedNodes={modifiedNodes}
            />
            <Tree
              heading="New Hierarchy"
              data={proposedVersion}
              onChange={handleReviewedChanges}
              hierarchyType={reviewedChanges?.hierarchyType}
              workflow={typeOfWorkflow}
              nextNode={searchString}
              onNextChange={handleNextChange}
              modifiedNodes={modifiedNodes}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            marginTop="size-300"
            gap="size-200"
          >
            {isReadOnly && (
              <Button variant="negative" onPress={handleCancel}>
                Cancel
              </Button>
            )}

            {typeOfWorkflow === DRAFT && !isReadOnly && (
              <>
                <Button variant="cta" onPress={handleSendForApproval}>
                  Send for approval
                </Button>
                <Button variant="primary" onPress={handleSaveAsDraft}>
                  Save as draft
                </Button>
                <Button variant="negative" onPress={handleCancel}>
                  Cancel
                </Button>
              </>
            )}

            {typeOfWorkflow === PENDING && !isReadOnly && (
              <>
                <>
                  <Button variant="cta" onPress={handleApprove}>
                    Approve
                  </Button>
                  <DialogTrigger>
                    <Button variant="primary">Request change</Button>
                    {(close) => (
                      <CommentsDialog
                        title="Request changes"
                        subTitle="You are requesting for changes. Do you want to continue?"
                        primaryActionLabel="Request change"
                        onClose={close}
                        onPrimaryAction={handleRequestChange}
                      />
                    )}
                  </DialogTrigger>
                  <DialogTrigger>
                    <Button variant="negative">Reject</Button>
                    {(close) => (
                      <CommentsDialog
                        title="Reject"
                        subTitle="This will reject the reviewed changes. Do you want to continue?"
                        primaryActionLabel="Reject"
                        onClose={close}
                        onPrimaryAction={handleReject}
                      />
                    )}
                  </DialogTrigger>
                </>
                <Button variant="negative" onPress={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Flex>
        </>
      )}
    </Provider>
  );
}

HierarchyWorkflow.propTypes = {
  match: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.objectOf(PropTypes.string),
    ])
  ).isRequired,
};
