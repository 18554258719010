import {
  Tabs,
  Flex,
  TabList,
  TabPanels,
  Item,
  Heading,
  TooltipTrigger,
  View,
  Tooltip,
  ActionButton,
  Button,
  Well,
  Text,
  LabeledValue,
  TextField,
  DialogContainer,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import {
  error as ErrorToast,
  success as SuccessToast,
} from "@react/react-spectrum/Toast";
import Flag from "@spectrum-icons/workflow/Flag";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import Edit from "@spectrum-icons/workflow/Edit";
import SaveFloppy from "@spectrum-icons/workflow/SaveFloppy";
import Collapsible from "../../../components/Common/Collapsible/Collapsible";
import {
  AccountMappingPath,
  ManageAccountMappingPath,
  GoldenRecordManagementPath,
} from "../../../constants/Routes";
import {
  StandardizedAccountGeneralAttributes,
  SourceSystemAccountAttributes,
} from "../../../constants/GoldenRecordAttributes";
import { AddressLabel } from "../../../constants/SlidingPaneLabels";
import ManageAccountGrouping from "./ManageAccountGrouping";
import { goldenRecordApi } from "../../../api/goldenRecordApi";
import useUserProfile from "../../../context/user-context";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import EditDunsDialog from "../../../components/Dialog/HMS/EditDunsDialog";

export default function ManageAccount() {
  const history = useHistory();
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const [isPageLoading, setPageLoading] = useState(false);

  const [orgAccountInfo, setOrgAccountInfo] = useState({});
  const [sourceSystemAccountInfo, setSourceSystemAccountInfo] = useState([]);
  const [dunsId, setDunsId] = useState("");
  const [editDuns, setEditDuns] = useState("");

  // display general account info
  const generalAttr = StandardizedAccountGeneralAttributes();

  // non editable firmographic attributes
  const [firmographicInfo, setFirmographicInfo] = useState({});
  const [firmographicAttr, setFirmographicAttr] = useState([]); // list of attribute names
  // editable firmographic attributes
  const [editableFirmographicInfo, setEditableFirmographicInfo] = useState({});
  const [editableFirmographicAttr, setEditableFirmographicAttr] = useState([]); // list of attribute names
  // calculating where to split firmographic attributes for dual column display
  const [leftColFirmographicAttr, setLeftColFirmographicAttr] = useState([]);
  const [rightColFirmographicAttr, setRightColFirmographicAttr] = useState([]);

  // page to return to (account mapping vs golden record)
  const [returnPage, setReturnPage] = useState(AccountMappingPath);

  const fetchResults = (duns) => {
    if (duns !== "" && duns !== null) {
      setPageLoading(true);
      goldenRecordApi
        .fetchFirmographicInfo({ duns_number: duns }, authState.accessToken)
        .then((data) => {
          setPageLoading(false);
          if (Object.keys(data).length > 0) {
            setFirmographicInfo(data.firmographic);
            const firmAttrWithoutObj = []; // list of firmographic attributes where key is not an object
            Object.keys(data.firmographic).forEach((attr) => {
              if (typeof data.firmographic[attr] !== "object")
                firmAttrWithoutObj.push(attr);
            });
            setFirmographicAttr(firmAttrWithoutObj);
            // editable firmographic attributes
            setEditableFirmographicInfo(data.firmographic_override_fields);
            setEditableFirmographicAttr(
              Object.keys(data.firmographic_override_fields)
            );
          } else {
            setFirmographicInfo({});
            setFirmographicAttr([]);
            setEditableFirmographicInfo({});
            setEditableFirmographicAttr([]);
          }
        })
        .catch((error) => {
          setPageLoading(false);
          ErrorToast(
            error.response?.data?.message ||
              "Failed to fetch firmographic info!",
            {
              timeout: 5000,
            }
          );
        });
    } else {
      setFirmographicInfo({});
      setFirmographicAttr([]);
      setEditableFirmographicInfo({});
      setEditableFirmographicAttr([]);
    }
  };

  useEffect(() => {
    if (history?.location?.state?.orgEntityAccInfo[0]) {
      setOrgAccountInfo(history?.location?.state?.orgEntityAccInfo[0]);
    }
    if (history?.location?.state?.sourceSystemAccInfo) {
      setSourceSystemAccountInfo(history?.location?.state?.sourceSystemAccInfo);
    }
    if (history?.location?.state?.goldenRecordPage) {
      setReturnPage(GoldenRecordManagementPath);
    }
    setDunsId(history?.location?.state?.dunsId);
  }, []);

  useEffect(() => {
    fetchResults(dunsId);
  }, [dunsId]);

  useEffect(() => {
    const firmographicAttrHalfway = Math.floor(
      (firmographicAttr.length + editableFirmographicAttr.length) / 2
    );
    setLeftColFirmographicAttr(
      editableFirmographicAttr.concat(
        firmographicAttr.slice(
          0,
          firmographicAttrHalfway - editableFirmographicAttr.length
        )
      )
    );
    setRightColFirmographicAttr(
      firmographicAttr.slice(
        firmographicAttrHalfway - editableFirmographicAttr.length,
        firmographicAttr.length
      )
    );
  }, [firmographicAttr, editableFirmographicAttr, dunsId]);

  const [updatedAttributes, setUpdatedAttributes] = useState({});

  // display source system account info
  const sourceSystemAttr = SourceSystemAccountAttributes();
  const addressLabelsColumn = AddressLabel();

  const handleBack = () => {
    history.push(returnPage, {
      searchFilter: history?.location?.state?.searchFilter,
    });
  };

  const handleOverride = () => {
    setPageLoading(true);
    goldenRecordApi
      .overrideFirmographicAttr(
        {
          from_state: editableFirmographicInfo,
          to_state: { ...editableFirmographicInfo, ...updatedAttributes },
          duns_number: orgAccountInfo.duns_number,
          user_id: user?.userId,
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        if (data) {
          // setDunsId(dunsId);
          setUpdatedAttributes({});
          SuccessToast(`${data?.message} (wf: ${data.wf_id})`, {
            timeout: 5000,
          });
        } else {
          ErrorToast(
            "Error overriding firmographic attributes. Please try again",
            { timeout: 5000 }
          );
        }
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message ||
            "Failed to override firmographic attributes",
          {
            timeout: 5000,
          }
        );
      });
  };

  const handleSaveDuns = (newDunsId) => {
    setPageLoading(true);
    goldenRecordApi
      .updateDuns(
        {
          duns_number: newDunsId === "" ? null : newDunsId,
          modifiedby: user?.userId,
        },
        orgAccountInfo.org_entity_id,
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        if (data?.success) {
          setDunsId(newDunsId);
          fetchResults(newDunsId);
          SuccessToast(`${data?.message} (wf: ${data.wf_id})`, {
            timeout: 5000,
          });
          history.push(ManageAccountMappingPath, {
            dunsId: newDunsId,
            orgEntityAccInfo: history?.location?.state?.orgEntityAccInfo,
            sourceSystemAccInfo: history?.location?.state?.sourceSystemAccInfo,
            searchFilter: history?.location?.state?.searchFilter,
          });
        } else {
          ErrorToast("Error updating duns number. Please try again", {
            timeout: 5000,
          });
        }
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Failed to update duns number",
          {
            timeout: 5000,
          }
        );
      });
  };

  const renderStandardizedAccountInfo = () => (
    <Flex direction="column" gap="size-125">
      <Flex gap="size-125">
        <Heading level={3}>ORG ID: {orgAccountInfo.org_entity_id}</Heading>
        {orgAccountInfo?.ds_standardized_flag && (
          <TooltipTrigger>
            <Button isQuiet>
              <View>
                <Flag />
              </View>
            </Button>
            <Tooltip>User Overriden Account</Tooltip>
          </TooltipTrigger>
        )}
      </Flex>
      <Well>
        <Collapsible label="Company Information">
          {generalAttr.map((attr) => (
            <Flex key={attr.key} gap="size-100">
              <LabeledValue
                label={`${attr.title}: `}
                value={orgAccountInfo[attr.key] || ""}
                labelPosition="side"
                labelAlign="end"
              />
            </Flex>
          ))}
        </Collapsible>
      </Well>
      <Well>
        <Collapsible label="Firmographic Information">
          <Flex direction="row-reverse" marginTop="10px">
            <Button
              isDisabled={Object.keys(updatedAttributes).length === 0}
              onPress={handleOverride}
            >
              <SaveFloppy color="informative" />
              <Text>Save</Text>
            </Button>
          </Flex>
          <Flex direction="row">
            <Flex flex={1} direction="column">
              <Flex height="24px">
                <LabeledValue
                  label="Duns Number"
                  value={dunsId || ""}
                  labelPosition="side"
                  labelAlign="end"
                />
                <TooltipTrigger>
                  <ActionButton isQuiet onPress={() => setEditDuns(true)}>
                    <View>
                      <Edit color="informative" size="S" />
                    </View>
                  </ActionButton>
                  <Tooltip>Update Duns Number</Tooltip>
                </TooltipTrigger>
              </Flex>
              {leftColFirmographicAttr.map((attr) => {
                if (attr !== "duns_number") {
                  if (editableFirmographicAttr.includes(attr)) {
                    return (
                      <Flex width="fit-content">
                        <TextField
                          key={editableFirmographicInfo[attr] || ""}
                          label={`${attr.replaceAll("_", " ")}: `}
                          defaultValue={editableFirmographicInfo[attr] || ""}
                          isQuiet
                          labelPosition="side"
                          labelAlign="end"
                          width="400px"
                          onChange={(val) =>
                            setUpdatedAttributes({
                              ...updatedAttributes,
                              [attr]: val,
                            })
                          }
                        />
                      </Flex>
                    );
                  }
                  return (
                    <LabeledValue
                      label={`${attr.replaceAll("_", " ")}: `}
                      value={firmographicInfo[attr] || ""}
                      labelPosition="side"
                      labelAlign="end"
                    />
                  );
                }
                return <></>;
              })}
            </Flex>
            <Flex flex={1} direction="column">
              {rightColFirmographicAttr.map((attr) => (
                <LabeledValue
                  label={`${attr.replaceAll("_", " ")}: `}
                  value={firmographicInfo[attr] || ""}
                  labelPosition="side"
                  labelAlign="end"
                />
              ))}
            </Flex>
          </Flex>
        </Collapsible>
      </Well>
    </Flex>
  );

  const renderSourceSystemAccountInfo = () => (
    <Flex direction="column" gap="size-125">
      <Heading level={3}>ORG ID: {orgAccountInfo.org_entity_id}</Heading>
      {Object.keys(sourceSystemAccountInfo).length > 0 &&
        sourceSystemAccountInfo.map((account) => (
          <Well>
            <Collapsible
              label={`${account.account_id} | ${account.source_system}`}
            >
              <Flex direction="row" gap="size-125">
                <Flex direction="column" flex={1}>
                  {sourceSystemAttr.map((attr) => (
                    <Flex key={attr.key}>
                      <LabeledValue
                        label={`${attr.title}: `}
                        value={account[attr.key] || ""}
                        labelPosition="side"
                        labelAlign="end"
                      />
                    </Flex>
                  ))}
                </Flex>
                <Flex flex={1}>
                  {account?.address.map((address, i) => (
                    <Well
                      key={address.address_id}
                      aria-labelledby={`address${i}`}
                      minHeight="fit-content"
                      width="100%"
                    >
                      <h3 id={`address${i}Label`}>
                        {i === 0 ? "Primary" : "Associated"} Address{" "}
                        {i >= 2 ? i : ""}
                      </h3>
                      {addressLabelsColumn.map((label) => (
                        <Flex key={label.key} gap="size-100">
                          <Text
                            UNSAFE_style={{
                              color: "#707070",
                              fontSize: "0.82rem",
                            }}
                          >
                            {label.title}:
                          </Text>
                          <Text>{address[label.key]}</Text>
                        </Flex>
                      ))}
                    </Well>
                  ))}
                </Flex>
              </Flex>
            </Collapsible>
          </Well>
        ))}
    </Flex>
  );

  return (
    <Flex margin="size-300" gap="size-150" wrap>
      <LoadingDialog isOpen={isPageLoading} />
      <Tabs aria-label="Manage CAM Account" density="compact">
        <TabList>
          <Item key="orgEntityAccount">Org Entity Account</Item>
          <Item key="sourceSystemAccounts">Source System Accounts</Item>
          <Item key="manageAccountGrouping">Manage Account Grouping</Item>
        </TabList>
        <TabPanels>
          <Item key="orgEntityAccount">{renderStandardizedAccountInfo()}</Item>
          <Item key="sourceSystemAccounts">
            {renderSourceSystemAccountInfo()}
          </Item>
          <Item key="manageAccountGrouping">
            <ManageAccountGrouping
              orgAccountInfo={orgAccountInfo}
              sourceSystemAccountInfo={sourceSystemAccountInfo}
              returnPage={returnPage}
            />
          </Item>
        </TabPanels>
      </Tabs>
      <Flex>
        <ActionButton isQuiet onPress={handleBack}>
          <ChevronLeft color="informative" />
          <Text>Account Mapping</Text>
        </ActionButton>
      </Flex>
      <DialogContainer onDismiss={() => setEditDuns(false)}>
        {editDuns && (
          <EditDunsDialog dunsId={dunsId} handleSaveDuns={handleSaveDuns} />
        )}
      </DialogContainer>
    </Flex>
  );
}
