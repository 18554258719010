import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const fetchFirmographicInfo = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(
    `/api/crosssystemaccounts/firmographic`,
    data
  );
  const responseData = await response.data;
  return responseData;
};

const overrideFirmographicAttr = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/override`, data);
  const responseData = await response.data;
  return responseData;
};

const updateDuns = async (data, orgId, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(
    `/api/goldenrecord/updateduns/${orgId}`,
    data
  );
  const responseData = await response.data;
  return responseData;
};

const modifyAccountGrouping = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/accountgrouping`, data);
  const responseData = await response.data;
  return responseData;
};

const fetchGoldenRecords = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/list`, data);
  const responseData = await response.data;
  return { results: responseData };
};

const fetchGoldenRecordDetails = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/details`, data);
  const responseData = await response.data;
  return responseData;
};

const fetchManualReviewList = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/manualreview`, data);
  const responseData = await response.data;
  return { results: responseData };
};

const fetchSuspects = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/suspects`, data);
  const responseData = await response.data;
  return responseData;
};

const mergeSuspect = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/suspects/merge`, data);
  const responseData = await response.data;
  return responseData;
};

const discardSuspect = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/goldenrecord/suspects/discard`, data);
  const responseData = await response.data;
  return responseData;
};

export const goldenRecordApi = {
  fetchFirmographicInfo,
  overrideFirmographicAttr,
  updateDuns,
  modifyAccountGrouping,
  fetchGoldenRecords,
  fetchGoldenRecordDetails,
  fetchManualReviewList,
  fetchSuspects,
  mergeSuspect,
  discardSuspect,
};
