import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  useDialogContainer,
  Flex,
  TextField,
} from "@adobe/react-spectrum";
import React, { useState } from "react";
import PropTypes from "prop-types";

export default function PromoteTreeDialog({
  onClose,
  value,
  accountId,
  accountName,
}) {
  const [comment, setComment] = useState("");
  const dialog = useDialogContainer();

  const handleConfirm = () => {
    onClose(value, comment);
    setComment("");
    dialog.dismiss();
  };

  const handleCancel = () => {
    setComment("");
    dialog.dismiss();
  };

  return (
    <Dialog>
      <Heading>Promote Subtree</Heading>
      <Divider />
      <Content>
        <Flex marginBottom="15px">
          {`${accountName} (${accountId}) will become a new parent node. The associated children will also move to this new subtree.`}
        </Flex>
        <TextField
          label="Comment"
          width="100%"
          value={comment}
          onChange={setComment}
        />
      </Content>
      <ButtonGroup>
        <Button onPress={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button onPress={handleConfirm} variant="positive">
          Promote
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

PromoteTreeDialog.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.objectOf(PropTypes.string),
    ])
  ).isRequired,
};
