import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const fetchDropdownOptions = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/dropdown`, data);
  const responseData = await response.data;
  return responseData;
};

const fetchAllWorkflows = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/list`, data);
  const responseData = await response.data;
  return { results: responseData };
};

const fetchWorkflowDetails = async (wfId, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/workflow/details/${wfId}`);
  const responseData = await response.data;
  return responseData;
};

const approveWorkflows = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/approve`, data);
  const responseData = await response.data.workflow_id_map;
  return responseData;
};

const rejectWorkflows = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/reject`, data);
  const responseData = await response.data.workflow_id_map;
  return responseData;
};

const fetchWorkflowTasks = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/tasks`, data);
  const responseData = await response.data;
  return { results: responseData };
};

const fetchWorkflowTaskDetails = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/task/details`, data);
  const responseData = await response.data;
  return responseData;
};

const approveTasks = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/task/approve`, data);
  const responseData = await response.data.task_id_map;
  return responseData;
};

const rejectTasks = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/workflow/task/reject`, data);
  const responseData = await response.data.task_id_map;
  return responseData;
};

export const workflowApi = {
  fetchDropdownOptions,
  fetchAllWorkflows,
  fetchWorkflowDetails,
  approveWorkflows,
  rejectWorkflows,
  fetchWorkflowTasks,
  fetchWorkflowTaskDetails,
  approveTasks,
  rejectTasks,
};
