import React from "react";
import { View, Form, Text } from "@adobe/react-spectrum";
import PropTypes from "prop-types";

export default function ViewEnrichedAttributesForm({ data, labels }) {
  const style = { color: "#4B4B4B", fontSize: "1rem" };

  return (
    <Form UNSAFE_style={{ backgroundColor: "#F5F5F5" }}>
      {labels.map((item) => (
        <View key={item.key} marginBottom={14}>
          <Text UNSAFE_style={{ color: "#707070", fontSize: "0.82rem" }}>
            {item.title}
          </Text>
          <View>
            <Text UNSAFE_style={style}> {data[item.key]} </Text>
          </View>
        </View>
      ))}
    </Form>
  );
}

ViewEnrichedAttributesForm.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType(
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    )
  ).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, key: PropTypes.string })
  ).isRequired,
};
