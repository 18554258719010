import React from "react";
import { Form, View, Text, Divider, Flex, Well } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import AddressCard from "./AddressCard/AddressCard";
import FirmographicCard from "./FirmographicCard/FirmographicCard";
import Collapsible from "../../Common/Collapsible/Collapsible";

const AccountMappingForm = (props) => {
  const { data, labels, sourceSystems, displaySourceSystems } = props;
  const style = { color: "#4B4B4B", fontSize: "1rem" };

  return (
    <Form UNSAFE_style={{ backgroundColor: "#F5F5F5" }}>
      {labels.map((item) => (
        <View key={item.key} marginBottom={14}>
          <Text UNSAFE_style={{ color: "#707070", fontSize: "0.82rem" }}>
            {item.title}
          </Text>
          <View>
            {item.key !== "address" && item.key !== "firmographic" && (
              <Text
                UNSAFE_style={
                  item.key === "street"
                    ? { ...style, whiteSpace: "pre-wrap" }
                    : style
                }
              >
                {data[item.key]}
              </Text>
            )}

            {item.key === "address" &&
              Object.prototype.hasOwnProperty.call(data, "address") && (
                <AddressCard addresses={data[item.key]} />
              )}

            {item.key === "duns_number" &&
              Object.prototype.hasOwnProperty.call(data, "duns_number") && (
                <FirmographicCard duns={data.duns_number} />
              )}
          </View>
        </View>
      ))}
      {displaySourceSystems && sourceSystems.length > 0 && (
        <Flex>
          <Divider size="M" />
          <Well>
            <Collapsible label="Source Systems">
              {sourceSystems.map((acc) => (
                <Flex direction="column">
                  <Text>{`${acc.account_id} | ${acc.account_name} | ${acc.source_system}`}</Text>
                  <Divider size="S" marginBottom="10px" marginTop="10px" />
                </Flex>
              ))}
            </Collapsible>
          </Well>
        </Flex>
      )}
    </Form>
  );
};

AccountMappingForm.defaultProps = {
  displaySourceSystems: false,
  sourceSystems: [],
};
AccountMappingForm.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType(
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.object)
    )
  ).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, key: PropTypes.string })
  ).isRequired,
  sourceSystems: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string, PropTypes.number))
  ),
  displaySourceSystems: PropTypes.bool,
};

export default AccountMappingForm;
