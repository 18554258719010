export const RootPath = "/cam/";
export const OktaLoginCallbackPath = `${RootPath}login/callback`;
export const AccountManagementPath = `${RootPath}accountmanagement`;
export const AppLogoutPath = `${RootPath}logout`;
export const DashboardPath = `${RootPath}dashboard`;
export const GroupsPath = `${RootPath}groups`;
export const ExternalAccountMappingPath = `${RootPath}eam`;
export const LogicalAccountGroupingPath = `${RootPath}lag`;
// export const HierarchyManagementPath = `${RootPath}hms`;
export const SearchPath = `${RootPath}search`;

export const GroupsRequestPath = `${GroupsPath}/requests`;
export const SentGroupRequestsPath = `${GroupsRequestPath}/sent`;
export const ReceivedGroupRequestsPath = `${GroupsRequestPath}/received`;
export const CreateAndSubscribeGroupsPath = `${GroupsPath}/createandsubscribe`;
export const ManageGroupsPath = `${GroupsPath}/manage`;
export const CreateGroupsPath = `${CreateAndSubscribeGroupsPath}/create`;
export const EditGroupsPath = `${ManageGroupsPath}/edit`;
// export const EmailRequestApprovalPath = `${ReceivedGroupRequestsPath}/:requestId/:requestType/:status`;
export const GroupsEmailRequestApprovalPath = `${ReceivedGroupRequestsPath}/:requestId/:requestType/:status`;

export const RemapExternalAccountPath = `${ExternalAccountMappingPath}/remap`;
export const SplitExternalAccountMappingsPath = `${ExternalAccountMappingPath}/split`;
export const CompleteSyncSetPath = `${ExternalAccountMappingPath}/completesync/:originalSourceAccountId`;

// export const BulkRegistrationPath = `${RootPath}selfserve/registration`;
export const BulkRegistrationPath = `${AccountManagementPath}/registration`;
export const OldRegistrationPath = `${RootPath}selfserve/registration`;
export const AccountMappingPath = `${AccountManagementPath}/mapping`;
export const OldAccountMappingPath = `${RootPath}crosssystemaccount`;
export const LookupHierarchyManagementPath = `${AccountManagementPath}/hierarchy`;
export const OrphanHierarchyManagementPath = `${AccountManagementPath}/orphan`;
export const SubsidiaryHierarchyManagementPath = `${AccountManagementPath}/subsidiary`;
// export const HMSEmailRequestApprovalPath = `${ReceivedHierarchyManagementPath}/:workflow/:status`;
export const WorkflowHierarchyManagementPath = `${AccountManagementPath}/workflows`;
export const HierarchyWorkflowPath = `${AccountManagementPath}/workflows/hms`;
export const HMSEmailRequestApprovalPath = `${WorkflowHierarchyManagementPath}/:workflowId`;
export const ImportExportPath = `${AccountManagementPath}/importexport`;

export const IntelligentMatchPatch = `${SearchPath}/intelligentmatch`;
export const MarketingSearchPath = `${SearchPath}/marketingsearch`;

export const ManageAccountMappingPath = `${AccountManagementPath}/manage`;
export const GoldenRecordManagementPath = `${AccountManagementPath}/goldenrecord`;

export const LookupLogicalAccountGroupingPath = `${LogicalAccountGroupingPath}/lookup`;
export const CreateLogicalAccountGroupingPath = `${LookupLogicalAccountGroupingPath}/create`;
export const OverrideLogicalAccountGroupingPath = `${LookupLogicalAccountGroupingPath}/override`;
export const ManageLogicalAccountGroupingPath = `${LogicalAccountGroupingPath}/manage`;
export const SentLogicalAccountGroupingPath = `${ManageLogicalAccountGroupingPath}/sent`;
export const ReceivedLogicalAccountGroupingPath = `${ManageLogicalAccountGroupingPath}/received`;
export const LagEmailRequestApprovalPath = `${ReceivedLogicalAccountGroupingPath}/:groupKey/:status`;
