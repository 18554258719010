export const STATUS_NEW = "New";
export const STATUS_INPROGRESS = "InProgress";
export const STATUS_PENDINGFORAPPROVAL = "PendingForApproval";
export const STATUS_APPROVED = "Approved";
export const STATUS_REJECTED = "Rejected";
export const STATUS_DRAFT = "Draft";
export const STATUS_AUTOAPPROVED = "Autoapproved";
export const STATUS_CHANGENOTIFICATION = "Change";
export const STATUS_DESCRIPTION_MAP = {
  [STATUS_NEW]: "New",
  [STATUS_INPROGRESS]: "In Progress",
  [STATUS_PENDINGFORAPPROVAL]: "Pending For Approval",
  [STATUS_APPROVED]: "Approved",
  [STATUS_REJECTED]: "Rejected",
  [STATUS_DRAFT]: "Draft",
  [STATUS_AUTOAPPROVED]: "Auto Approved",
  [STATUS_CHANGENOTIFICATION]: "Change Notification",
};

export const HIERARCHY_ECH = "ECH Hierarchy";
export const HIERARCHY_GTM = "GTM Hierarchy";
export const HIERARCHY_LEGAL = "DNB Legal Hierarchy";

export const GTMH = "GTMH";
export const ECH = "ECH";
export const DNB = "DNB";
export const ACCOUNT = "ACCOUNT";
