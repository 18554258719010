import React, { useState } from "react";

import {
  Dialog,
  Heading,
  Divider,
  Content,
  Form,
  Button,
  ButtonGroup,
  TextArea,
  Text,
} from "@adobe/react-spectrum";
import PropTypes from "prop-types";

export default function CommentsDialog({
  title,
  subTitle,
  primaryActionLabel,
  onClose,
  onPrimaryAction,
}) {
  const [dialogComments, setDialogComments] = useState("");

  return (
    <Dialog>
      <Heading>{title}</Heading>
      <Divider />
      <ButtonGroup>
        <Button variant="secondary" onPress={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant="negative"
          onPress={() => onPrimaryAction(dialogComments)}
        >
          {primaryActionLabel}
        </Button>
      </ButtonGroup>
      <Content>
        {subTitle && <Text>{subTitle}</Text>}
        <Form>
          <TextArea
            label="Comments"
            value={dialogComments}
            onChange={setDialogComments}
          />
        </Form>
      </Content>
    </Dialog>
  );
}

CommentsDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  primaryActionLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrimaryAction: PropTypes.func.isRequired,
};
