import { Flex, Text, Well } from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import { error as ErrorToast } from "@react/react-spectrum/Toast";
import Collapsible from "../../../Common/Collapsible/Collapsible";
import { goldenRecordApi } from "../../../../api/goldenRecordApi";

export default function FirmographicCard(props) {
  const { duns } = props;
  const [firmographicList, setFirmographicList] = useState([]);
  const [firmographicInfo, setFirmographicInfo] = useState({});
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (duns !== "" && duns !== null) {
      goldenRecordApi
        .fetchFirmographicInfo({ duns_number: duns }, authState.accessToken)
        .then((data) => {
          if (Object.keys(data).length === 0) {
            setFirmographicList([]);
            setFirmographicInfo({});
          } else {
            setFirmographicInfo(data.firmographic);
            setFirmographicList(Object.keys(data.firmographic));
          }
        })
        .catch((error) => {
          ErrorToast(
            error.response?.data?.message ||
              "Failed to fetch firmographic info!",
            {
              timeout: 5000,
            }
          );
        });
    }
  }, [duns]);

  return (
    <Well minHeight="fit-content">
      <Collapsible label="Firmographic Information">
        {firmographicList.length > 0 &&
          firmographicList.map((attr) => {
            if (typeof firmographicInfo[attr] === "string") {
              return (
                <Flex key={attr} gap="size-100" marginBottom="10px">
                  <Text
                    UNSAFE_style={{ color: "#707070", fontSize: "0.82rem" }}
                    width="150px"
                  >
                    {attr.replaceAll("_", " ")}:
                  </Text>
                  <Text>
                    {firmographicInfo[attr] === null
                      ? ""
                      : firmographicInfo[attr].toString()}
                  </Text>
                </Flex>
              );
            }
            return <></>;
          })}
      </Collapsible>
    </Well>
  );
}

FirmographicCard.propTypes = {
  duns: PropTypes.string.isRequired,
};
