import { Flex } from "@adobe/react-spectrum";
import { LoginCallback, SecureRoute } from "@okta/okta-react";
import React from "react";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import { Redirect, Route, Switch } from "react-router-dom";
import CreateGroupForm from "../components/Forms/GroupsForm/CreateGroupForm";
import EditGroupForm from "../components/Forms/GroupsForm/EditGroupForm";
import Logout from "../components/Logout/Logout";
import RequireRbac from "../components/RequireRbac/RequireRbac";
import {
  AccountMappingPath,
  AppLogoutPath,
  CompleteSyncSetPath,
  CreateAndSubscribeGroupsPath,
  CreateGroupsPath,
  DashboardPath,
  EditGroupsPath,
  GroupsEmailRequestApprovalPath,
  ExternalAccountMappingPath,
  GroupsRequestPath,
  ManageGroupsPath,
  OktaLoginCallbackPath,
  ReceivedGroupRequestsPath,
  RemapExternalAccountPath,
  RootPath,
  SentGroupRequestsPath,
  SplitExternalAccountMappingsPath,
  LookupLogicalAccountGroupingPath,
  CreateLogicalAccountGroupingPath,
  OverrideLogicalAccountGroupingPath,
  ManageLogicalAccountGroupingPath,
  SentLogicalAccountGroupingPath,
  ReceivedLogicalAccountGroupingPath,
  LagEmailRequestApprovalPath,
  BulkRegistrationPath,
  LookupHierarchyManagementPath,
  OrphanHierarchyManagementPath,
  SubsidiaryHierarchyManagementPath,
  IntelligentMatchPatch,
  MarketingSearchPath,
  WorkflowHierarchyManagementPath,
  ManageAccountMappingPath,
  ImportExportPath,
  HMSEmailRequestApprovalPath,
  HierarchyWorkflowPath,
  GoldenRecordManagementPath,
  OldAccountMappingPath,
  OldRegistrationPath,
} from "../constants/Routes";
import { GroupsProvider } from "../context/groups-context";

import AccountMapping from "./AccountMapping/AccountMapping";
import ManageAccount from "./AccountMapping/ManageAccount/ManageAccount";

import Dashboard from "./Dashboard/Dashboard";
import CompleteSyncSet from "./ExternalAccountManagement/ExternalAccountMapping/CompleteSyncSet/CompleteSyncSet";
import ExternalAccountMapping from "./ExternalAccountManagement/ExternalAccountMapping/ExternalAccountMapping";
import RemapExternalAccount from "./ExternalAccountManagement/ExternalAccountMapping/RemapExternalAccount/RemapExternalAccount";
import SplitExternalAccountMapping from "./ExternalAccountManagement/ExternalAccountMapping/SplitExternalAccountMapping/SplitExternalAccountMapping";
import CreateAndSubscribeGroups from "./Groups/CreateAndSubscribeGroups";
import GroupRequests from "./Groups/GroupRequests/GroupRequests";
import ReceivedGroupRequests from "./Groups/GroupRequests/ReceivedGroupRequests";
import SentGroupRequests from "./Groups/GroupRequests/SentGroupRequests";
import ManageGroups from "./Groups/ManageGroups";
import GoldenRecordManagement from "./GoldenRecordManagement/GoldenRecordManagement";
import HierarchyManagement from "./HierarchyManagement/HierarchyManagement";
import OrphanAccountManagement from "./HierarchyManagement/OrphanAccountManagement/OrphanAccountManagement";
import SubsidiaryManagement from "./HierarchyManagement/SubsidiaryManagement/SubsidiaryManagement"; // maybe can delete

import ManageWorkflows from "./HierarchyManagement/ManageHMS/ManageWorkflows"; // new
import HMSWorkflow from "./HierarchyManagement/ManageHMS/WorkflowDetails/HMSWorkflow"; // new
import ImportExport from "./HierarchyManagement/ImportExport/ImportExport";

import IntelligentMatch from "./Search/IntelligentMatch";
import MarketingSearch from "./Search/MarketingSearch";
import LogicalAccountGrouping from "./LogicalAccountGrouping/LogicalAccountGrouping";
import CreateLAG from "./LogicalAccountGrouping/CreateLAG/CreateLAG";
import OverrideLAG from "./LogicalAccountGrouping/OverrideLAG/OverrideLAG";
import ManageLAG from "./LogicalAccountGrouping/ManageLAG/ManageLAG";
import ReceivedLAGRequests from "./LogicalAccountGrouping/ManageLAG/LAGRequests/ReceivedLAGRequests";
import SentLAGRequests from "./LogicalAccountGrouping/ManageLAG/LAGRequests/SentLAGRequests";
import Home from "./Home/Home";
import BulkRegistration from "./SelfServe/BulkRegistration";

export default function RoutePages() {
  const renderRoutesPages = () => (
    <Flex direction="column" UNSAFE_className="body-content" flex>
      <Switch>
        <SecureRoute exact path={RootPath} component={Home} />
        <Route path={OktaLoginCallbackPath} component={LoginCallback} />
        <SecureRoute
          exact
          path={AccountMappingPath}
          component={AccountMapping}
        />
        <Route exact path={OldAccountMappingPath}>
          <Redirect to={AccountMappingPath} />
        </Route>
        <Route exact path={AppLogoutPath} component={Logout} />
        <SecureRoute exact path={DashboardPath} component={Dashboard} />
        <SecureRoute
          exact
          path={GroupsRequestPath}
          render={() => (
            <GroupsProvider>
              <GroupRequests />
            </GroupsProvider>
          )}
        />
        <SecureRoute
          exact
          path={SentGroupRequestsPath}
          render={() => (
            <GroupsProvider>
              <SentGroupRequests />
            </GroupsProvider>
          )}
        />
        <SecureRoute
          exact
          path={ReceivedGroupRequestsPath}
          render={() => (
            <GroupsProvider>
              <ReceivedGroupRequests />
            </GroupsProvider>
          )}
        />
        <SecureRoute
          exact
          path={GroupsEmailRequestApprovalPath}
          render={() => <ReceivedGroupRequests />}
        />
        <SecureRoute
          exact
          path={CreateAndSubscribeGroupsPath}
          render={() => (
            <GroupsProvider>
              <CreateAndSubscribeGroups />
            </GroupsProvider>
          )}
        />
        <SecureRoute
          exact
          path={CreateGroupsPath}
          render={() => (
            <GroupsProvider>
              <CreateGroupForm />
            </GroupsProvider>
          )}
        />
        <SecureRoute
          exact
          path={ManageGroupsPath}
          render={() => (
            <GroupsProvider>
              <ManageGroups />
            </GroupsProvider>
          )}
        />
        <SecureRoute
          exact
          path={EditGroupsPath}
          render={() => (
            <GroupsProvider>
              <EditGroupForm />
            </GroupsProvider>
          )}
        />
        <SecureRoute
          exact
          path={ExternalAccountMappingPath}
          render={() => <ExternalAccountMapping />}
        />
        <SecureRoute
          exact
          path={RemapExternalAccountPath}
          render={() => (
            <RequireRbac
              allowedFunctions={["eam"]}
              redirectPath={ExternalAccountMappingPath}
            >
              <RemapExternalAccount />
            </RequireRbac>
          )}
        />
        <SecureRoute
          exact
          path={SplitExternalAccountMappingsPath}
          render={() => (
            <RequireRbac
              allowedFunctions={["eam"]}
              redirectPath={ExternalAccountMappingPath}
            >
              <SplitExternalAccountMapping />
            </RequireRbac>
          )}
        />
        <SecureRoute
          exact
          path={CompleteSyncSetPath}
          render={() => (
            <RequireRbac
              allowedFunctions={["eam"]}
              redirectPath={ExternalAccountMappingPath}
            >
              <CompleteSyncSet />
            </RequireRbac>
          )}
        />

        <SecureRoute
          exact
          path={LookupLogicalAccountGroupingPath}
          render={() => <LogicalAccountGrouping />}
        />
        <SecureRoute
          exact
          path={CreateLogicalAccountGroupingPath}
          render={() => <CreateLAG />}
        />
        <SecureRoute
          exact
          path={OverrideLogicalAccountGroupingPath}
          render={() => <OverrideLAG />}
        />
        <SecureRoute
          exact
          path={ManageLogicalAccountGroupingPath}
          render={() => <ManageLAG />}
        />
        <SecureRoute
          exact
          path={SentLogicalAccountGroupingPath}
          render={() => <SentLAGRequests />}
        />
        <SecureRoute
          exact
          path={LagEmailRequestApprovalPath}
          render={() => <ReceivedLAGRequests />}
        />
        <SecureRoute
          exact
          path={ReceivedLogicalAccountGroupingPath}
          render={() => <ReceivedLAGRequests />}
        />

        <SecureRoute
          exact
          path={BulkRegistrationPath}
          render={() => <BulkRegistration />}
        />
        <Route exact path={OldRegistrationPath}>
          <Redirect to={BulkRegistrationPath} />
        </Route>
        <SecureRoute
          exact
          path={LookupHierarchyManagementPath}
          render={() => (
            // <DndProvider backend={HTML5Backend}>
            <HierarchyManagement />
            // </DndProvider>
          )}
        />
        <SecureRoute
          exact
          path={GoldenRecordManagementPath}
          render={() => <GoldenRecordManagement />}
        />
        <SecureRoute
          exact
          path={OrphanHierarchyManagementPath}
          render={() => <OrphanAccountManagement />}
        />
        <SecureRoute
          exact
          path={SubsidiaryHierarchyManagementPath}
          render={() => <SubsidiaryManagement />}
        />
        <SecureRoute
          exact
          path={ImportExportPath}
          render={() => <ImportExport />}
        />
        <SecureRoute
          exact
          path={WorkflowHierarchyManagementPath}
          render={() => <ManageWorkflows />}
        />
        <SecureRoute
          exact
          path={HMSEmailRequestApprovalPath}
          render={() => <ManageWorkflows />}
        />
        <SecureRoute
          exact
          path={HierarchyWorkflowPath.concat("/:wfId")}
          component={HMSWorkflow}
        />
        <SecureRoute
          exact
          path={IntelligentMatchPatch}
          render={() => <IntelligentMatch />}
        />
        <SecureRoute
          exact
          path={MarketingSearchPath}
          render={() => <MarketingSearch />}
        />
        <SecureRoute
          exact
          path={ManageAccountMappingPath}
          render={() => <ManageAccount />}
        />

        <Route path="*">
          <Redirect to={RootPath} />
        </Route>
      </Switch>
    </Flex>
  );

  return <>{renderRoutesPages()}</>;
}
