import {
  Dialog,
  Heading,
  Divider,
  Content,
  Form,
  TextField,
  Button,
  ButtonGroup,
  useDialogContainer,
  TextArea,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function NodeDialog({
  onClose,
  nodeName,
  nodeId,
  action,
  nodeComments,
}) {
  const [name, setName] = useState(nodeName);
  const [id, setId] = useState(nodeId);
  const [comments, setComments] = useState(nodeComments);

  const dialog = useDialogContainer();

  useEffect(() => {
    if (action === "Add") {
      setId("System Generated");
    }
  }, []);

  const handleSave = () => {
    if (action === "Add") {
      onClose(name, "", comments);
    } else {
      onClose(name, id, comments);
    }

    dialog.dismiss();
  };

  return (
    <Dialog>
      <Heading>{action}</Heading>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            label="Node Name"
            value={name}
            onChange={setName}
          />
          <TextField isDisabled label="Id" value={id} onChange={setId} />
          <TextArea
            label="Comments"
            value={comments}
            placeholder="Enter comments"
            onChange={setComments}
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="negative" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button variant="cta" onPress={handleSave}>
          Save
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

NodeDialog.defaultProps = {
  nodeName: "",
  nodeId: "",
  nodeComments: "",
};

NodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  nodeName: PropTypes.string,
  nodeId: PropTypes.number,
  action: PropTypes.string.isRequired,
  nodeComments: PropTypes.string,
};
