import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import { error as ErrorToast } from "@react/react-spectrum/Toast";
import "react-sliding-pane/dist/react-sliding-pane.css";
import CamSlidingPane from "../../Common/CamSlidingPane/CamSlidingPane";
import { crossSystemAccountApi } from "../../../api/lookupApi";
import { OrgEntityAccountsLabel } from "../../../constants/SlidingPaneLabels";
import AccountMappingForm from "../../Forms/AccountMappingForm/AccountMappingForm";
import LoadingDialog from "../LoadingDialog";

export default function GetORGDetailsDialog({
  onClose,
  isCalled,
  open,
  orgId,
  sourceSystems,
  displaySourceSystems,
}) {
  const [nodeValue, setNodeValue] = useState({});
  const { authState } = useOktaAuth();
  const [isLoadingDialog, setLoadingDialog] = useState(false);

  useEffect(() => {
    if (isCalled && orgId !== undefined) {
      setLoadingDialog(true);
      crossSystemAccountApi
        .fetchAccountMappings({ org_entity_id: orgId }, authState.accessToken)
        .then((data) => {
          setLoadingDialog(false);
          setNodeValue(data[0].org_entity_account);
        })
        .catch((error) => {
          setLoadingDialog(false);
          console.log(error);
          ErrorToast("Error fetching account details", {
            timeout: 5000,
          });
        });
    }
  }, [orgId, isCalled]);

  return (
    <>
      <LoadingDialog isOpen={isLoadingDialog} />
      {open && (
        <CamSlidingPane
          isPaneOpenFlag={open}
          handleOpenDialog={onClose}
          paneTitle="Details"
          Component={
            <AccountMappingForm
              data={nodeValue}
              labels={OrgEntityAccountsLabel()}
              sourceSystems={sourceSystems}
              displaySourceSystems={displaySourceSystems}
            />
          }
        />
      )}
    </>
  );
}

GetORGDetailsDialog.defaultProps = {
  orgId: "",
  displaySourceSystems: false,
  sourceSystems: [],
};

GetORGDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isCalled: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sourceSystems: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string, PropTypes.number))
  ),
  displaySourceSystems: PropTypes.bool,
};
