import { ActionButton, Flex, View } from "@adobe/react-spectrum";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import ChevronUp from "@spectrum-icons/workflow/ChevronUp";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import "./collapsible.css";

export default function Collapsible({ children, label }) {
  const [isOpen, setIsOpen] = useState(false);

  const parentRef = useRef(null);

  return (
    <View>
      <Flex direction="row">
        <h3>{label}</h3>
        <Flex justifyContent="end" UNSAFE_style={{ marginLeft: "auto" }}>
          <ActionButton onPress={() => setIsOpen(!isOpen)}>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </ActionButton>
        </Flex>
      </Flex>
      <View
        UNSAFE_className="parent-content"
        ref={parentRef}
        UNSAFE_style={
          isOpen
            ? {
                height: `${
                  parentRef?.current?.UNSAFE_getDOMNode()?.scrollHeight
                }px`,
              }
            : { height: "0px" }
        }
      >
        <View>{children}</View>
      </View>
    </View>
  );
}
Collapsible.defaultProps = {
  children: [],
  label: "",
};
Collapsible.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
};
