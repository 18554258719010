import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  useDialogContainer,
  Flex,
} from "@adobe/react-spectrum";
import React from "react";
import PropTypes from "prop-types";

export default function ClearTreeDialog({ setTreeSelected }) {
  const dialog = useDialogContainer();

  const handleConfirm = () => {
    setTreeSelected([]);
    dialog.dismiss();
  };

  const handleCancel = () => {
    dialog.dismiss();
  };

  return (
    <Dialog>
      <Heading>Clear Tree</Heading>
      <Divider />
      <Content>
        <Flex marginBottom="15px">
          Clearing this tree will clear all unsubmitted changes. Proceed to
          clear?
        </Flex>
      </Content>
      <ButtonGroup>
        <Button onPress={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button onPress={handleConfirm} variant="positive">
          Clear
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

ClearTreeDialog.propTypes = {
  setTreeSelected: PropTypes.func.isRequired,
};
