export const CamFunctions = () => [
  { id: "eam", label: "EAM" },
  { id: "mcs", label: "MultiCRM Sync" },
  { id: "lag", label: "LAG" },
  { id: "self_serve", label: "Self Serve" },
  { id: "hms", label: "HMS" },
];

export const HMS = "hms";
export const EAM = "eam";
export const LAG = "lag";

export default CamFunctions;
