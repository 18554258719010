import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  TextField,
  useDialogContainer,
  Picker,
  Item,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import { hierarchyApi } from "../../../api/hierarchyApi";

export default function CloneGTMHierarchyDialog({ dunsId, name, onClose }) {
  const dialog = useDialogContainer();
  const [hierarchyType, setHierarchyType] = useState("GTM Hierarchy");
  const [hierarchyTypeList, setHierarchyTypeList] = useState([]);
  const [comment, setComment] = useState("");
  const [clonedName, setClonedName] = useState(name);
  const nodeInfo = {};
  const resp = {};
  const { authState } = useOktaAuth();
  console.log("CloneGTMHierarchyDialog");

  useEffect(() => {
    if (hierarchyTypeList.length === 0 || hierarchyTypeList === undefined) {
      hierarchyApi
        .fetchGTMHierarchyTypes(authState.accessToken)
        .then((data) => {
          setHierarchyTypeList(data);
        });
    }
  }, [hierarchyTypeList]);

  const handleClone = () => {
    nodeInfo.parentId = dunsId;
    nodeInfo.hierarchyType = hierarchyType;
    nodeInfo.comment = comment;
    nodeInfo.name = clonedName;
    resp.data = nodeInfo;
    resp.action = "clone";
    hierarchyApi.cloneHierarchy(nodeInfo, authState.accessToken);
    onClose(resp);
    setHierarchyType();
    dialog.dismiss();
  };

  const handleCancel = () => {
    resp.data = null;
    resp.action = "cancel";
    onClose(resp);
    dialog.dismiss();
  };

  return (
    <Dialog>
      <Heading>Clone</Heading>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <Picker
            label="Choose hierarchy type"
            selectedKey={hierarchyType}
            onSelectionChange={setHierarchyType}
          >
            {hierarchyTypeList.map((hierarchy) => (
              <Item key={hierarchy}>{hierarchy}</Item>
            ))}
          </Picker>
          <TextField
            autoFocus
            label="Name"
            value={clonedName}
            onChange={setClonedName}
            isRequired
          />
          <TextField
            autoFocus
            label="Comment"
            value={comment}
            onChange={setComment}
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button onPress={handleClone}>Save</Button>
        <Button onPress={handleCancel}>Cancel</Button>
      </ButtonGroup>
    </Dialog>
  );
}

CloneGTMHierarchyDialog.propTypes = {
  dunsId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
