import {
  Flex,
  Tabs,
  TabList,
  Item,
  TabPanels,
  View,
} from "@adobe/react-spectrum";
import React from "react";
import ViewGoldenRecords from "./ViewGoldenRecords/ViewGoldenRecords";
import useValidUserAccess from "../../hooks/useValidUserAccess";
import { ADMIN, OPERATOR, SUPERUSER, USER } from "../../constants/RoleType";
import { HMS } from "../../constants/CamFunctions";
import ManualReview from "./ManualReview/ManualReview";

export default function GoldenRecordManagement() {
  const isHmsActionAllowed = useValidUserAccess(
    [ADMIN, SUPERUSER, OPERATOR, USER],
    HMS
  );

  return (
    <Flex margin="size-300" gap="size-125" direction="column" width="90%">
      {!isHmsActionAllowed && (
        <View marginTop="size-200">
          You are not authorized to access this feature. To gain access please
          subscribe to <b>GRP-HMS</b> under the Groups tab. Select role{" "}
          <b>user</b> for read-only access and <b>admin</b> for edit access.
        </View>
      )}
      {isHmsActionAllowed && (
        <Tabs aria-label="Golden Record Management">
          <TabList>
            <Item key="goldenRecords">Golden Records</Item>
            <Item key="matchQueue">Manual Review</Item>
          </TabList>
          <TabPanels>
            <Item key="goldenRecords">
              <ViewGoldenRecords />
            </Item>
            <Item key="matchQueue">
              <ManualReview />
            </Item>
          </TabPanels>
        </Tabs>
      )}
    </Flex>
  );
}
