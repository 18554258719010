import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  TextField,
  useDialogContainer,
} from "@adobe/react-spectrum";
import React, { useState } from "react";
import PropTypes from "prop-types";

export default function MoveNodeDialog({ onClose, value }) {
  const dialog = useDialogContainer();
  const [newParentId, setNewParentId] = useState();

  const handleSave = () => {
    onClose(value, newParentId);
    setNewParentId();
    dialog.dismiss();
  };

  const handleCancel = () => {
    setNewParentId();
    dialog.dismiss();
  };

  return (
    <Dialog>
      <Heading>Move Node</Heading>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            label="New Parent ID"
            onChange={setNewParentId}
            isRequired
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button onPress={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button onPress={handleSave} variant="positive">
          Move
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

MoveNodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  value: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.objectOf(PropTypes.string),
    ])
  ).isRequired,
};
