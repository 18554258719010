import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const importFiles = async (files, wfType, wfSubType, createdBy, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const formData = new FormData();
  for (let i = 0; i < files.length; i += 1) {
    formData.append("files", files[i]);
  }
  formData.append("wf_type", wfType);
  formData.append("wf_sub_type", wfSubType);
  formData.append("created_by", createdBy);

  const response = await axios.post("/api/bulkchanges/import", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const responseData = await response.data.file_import_status;
  return responseData;
};

const exportFile = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/bulkchanges/export`, data, {
    responseType: "blob",
  });
  const responseData = await response.data;
  return responseData;
};

export const bulkChangesApi = {
  importFiles,
  exportFile,
};
