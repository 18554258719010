import {
  Tabs,
  Flex,
  TabList,
  TabPanels,
  Item,
  Provider,
  defaultTheme,
  Content,
  Form,
  TextField,
  ButtonGroup,
  Button,
  Text,
  Heading,
  Link as V3Link,
} from "@adobe/react-spectrum";
import { error as ErrorToast } from "@react/react-spectrum/Toast";
import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { camAIApi } from "../../api/camAIApi";
// import camAccountApi from "../../api/camAccountApi";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
// import EmbedAccountDialog from "../../components/Dialog/EmbedAccountDialog";
import Table from "../../components/Common/Table";
import CamSlidingPane from "../../components/Common/CamSlidingPane/CamSlidingPane";
import ViewEnrichedAttributesForm from "../../components/Forms/SearchForm/ViewEnrichedAttributesForm";
import { AccountGroupingQueryMatchingAccounts } from "../../constants/Columns";
import { EnrichedAttributesLabel } from "../../constants/SlidingPaneLabels";

export default function IntelligentMatch() {
  const [accountGroupingQuery, setAccountGroupingQuery] = useState({
    account_name: "",
    street: "",
    city: "",
    country: "",
    state: "",
    postalcode: "",
    domain: "",
  });
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const [showingQueryAlert, setShowingQueryAlert] = useState(false);
  const [accountGroupingQueryResult, setAccountGroupingQueryResult] = useState(
    []
  );
  const [hasAccountGroupingMatches, setHasAccountGroupingMatches] =
    useState(false);
  const [isAccountGrouping, setIsAccountGrouping] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const accountGroupingMatchColumns = AccountGroupingQueryMatchingAccounts();
  const enrichedAttributesLabel = EnrichedAttributesLabel();
  const { authState } = useOktaAuth();

  const handleAccountGroupingQuery = () => {
    setPageLoading(true);
    setIsAccountGrouping(true);
    camAIApi
      .queryAccount(
        {
          data_source: "STANDARDIZED",
          query_account: accountGroupingQuery,
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        if (Array.isArray(data)) {
          setHasAccountGroupingMatches(true);
          setAccountGroupingQueryResult(data);
        } else if (
          typeof data === "object" &&
          Object.prototype.hasOwnProperty.call(data, "result") &&
          Object.prototype.hasOwnProperty.call(data, "reason")
        ) {
          setHasAccountGroupingMatches(false);
          setAccountGroupingQueryResult(`${data.result} - ${data.reason}`);
        } else {
          setHasAccountGroupingMatches(false);
          setAccountGroupingQueryResult(
            typeof data === "object" ? JSON.stringify(data) : data.toString()
          );
        }
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(error.response?.data?.message || "Error Querying Account!", {
          timeout: 5000,
        });
        setHasAccountGroupingMatches(false);
        setAccountGroupingQueryResult("Error querying, please try again");
        // setIsQueryError(true);
      });
    setShowingQueryAlert(true);
  };

  function handleGetDunsAttributes(dunsNumber) {
    setPageLoading(true);
    camAIApi
      .queryAccount(
        {
          data_source: "DUNS",
          query_account: { duns_number: dunsNumber },
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        setSidePaneData(data);
        setIsSidePaneOpen(true);
      })
      .catch((error) => {
        setPageLoading(false);
        console.log(error);
        ErrorToast(error.response?.data?.message || "Error Querying Account!", {
          timeout: 5000,
        });
      });
  }

  const renderCell = (colKey, row) => {
    if (colKey === "duns") {
      return (
        <Text>
          <V3Link isQuiet onPress={() => handleGetDunsAttributes(row.duns)}>
            {Object.hasOwnProperty.call(row, colKey) ? row[colKey] : ""}
          </V3Link>
        </Text>
      );
    }
    let cellStyle = {};

    if (!Object.hasOwnProperty.call(row, "percentage_match"))
      cellStyle = { color: "black" };
    else {
      const percentageMatch = Number.isInteger(row.percentage_match)
        ? row.percentage_match
        : parseInt(row.percentage_match.replace("%", ""), 10);
      if (percentageMatch >= 90) cellStyle = { color: "green" };
      else if (percentageMatch >= 75) cellStyle = { color: "#d69702" };
      else cellStyle = { color: "red" };
    }
    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  const renderAccountGrouping = () => (
    <>
      <Flex gap="10px" direction="column">
        <LoadingDialog isOpen={isPageLoading} />
        <Flex direction="column">
          <Content>
            <Form width="50%">
              <TextField
                autoFocus
                label="Account Name"
                value={accountGroupingQuery.account_name}
                onChange={(value) =>
                  setAccountGroupingQuery({
                    ...accountGroupingQuery,
                    account_name: value,
                  })
                }
                width="620px"
                isRequired
              />
              <TextField
                autoFocus
                label="Street"
                value={accountGroupingQuery.street}
                onChange={(value) =>
                  setAccountGroupingQuery({
                    ...accountGroupingQuery,
                    street: value,
                  })
                }
                width="620px"
                isRequired
              />
              <Flex gap="10px" width="620px">
                <TextField
                  autoFocus
                  label="City"
                  value={accountGroupingQuery.city}
                  onChange={(value) =>
                    setAccountGroupingQuery({
                      ...accountGroupingQuery,
                      city: value,
                    })
                  }
                  width="305px"
                  isRequired
                />
                <TextField
                  autoFocus
                  label="State"
                  value={accountGroupingQuery.state}
                  onChange={(value) =>
                    setAccountGroupingQuery({
                      ...accountGroupingQuery,
                      state: value,
                    })
                  }
                  width="305px"
                />
              </Flex>
              <Flex gap="10px" width="620px">
                <TextField
                  autoFocus
                  label="Country"
                  value={accountGroupingQuery.country}
                  onChange={(value) =>
                    setAccountGroupingQuery({
                      ...accountGroupingQuery,
                      country: value,
                    })
                  }
                  width="305px"
                  isRequired
                />
                <TextField
                  autoFocus
                  label="Postal Code"
                  value={accountGroupingQuery.postalcode}
                  onChange={(value) =>
                    setAccountGroupingQuery({
                      ...accountGroupingQuery,
                      postalcode: value,
                    })
                  }
                  width="305px"
                />
              </Flex>
              <TextField
                autoFocus
                label="Domain"
                value={accountGroupingQuery.domain}
                onChange={(value) =>
                  setAccountGroupingQuery({
                    ...accountGroupingQuery,
                    domain: value,
                  })
                }
                width="620px"
              />
            </Form>
          </Content>
          <ButtonGroup marginTop={20}>
            <Button
              onPress={handleAccountGroupingQuery}
              isDisabled={
                accountGroupingQuery.account_name === "" ||
                accountGroupingQuery.street === "" ||
                accountGroupingQuery.city === "" ||
                accountGroupingQuery.country === ""
              }
            >
              Query
            </Button>
          </ButtonGroup>
        </Flex>
        {showingQueryAlert && isAccountGrouping ? (
          <Flex marginTop="size-325" direction="column">
            <Heading level={3} UNSAFE_style={{ textDecoration: "underline" }}>
              Result
            </Heading>
            {hasAccountGroupingMatches && isAccountGrouping ? (
              <Table
                columns={accountGroupingMatchColumns}
                rows={
                  Array.isArray(accountGroupingQueryResult)
                    ? accountGroupingQueryResult
                    : []
                }
                // rows={accountGroupingQueryResult || []}
                renderCell={renderCell}
                selectionMode="none"
                density="compact"
              />
            ) : (
              <Flex direction="column">
                {isAccountGrouping && (
                  <Text>
                    {accountGroupingQueryResult[0]?.reason ||
                      accountGroupingQueryResult}
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
      {isSidePaneOpen && (
        <CamSlidingPane
          isPaneOpenFlag={isSidePaneOpen}
          handleOpenDialog={() => setIsSidePaneOpen(false)}
          paneTitle="View DUNS Attributes"
          Component={
            <ViewEnrichedAttributesForm
              data={sidePaneData}
              labels={enrichedAttributesLabel}
            />
          }
        />
      )}
    </>
  );

  return (
    <Provider theme={defaultTheme} colorScheme="light">
      <Flex margin="size-300" gap="size-150" wrap>
        <Tabs aria-label="Chat log density example" density="compact">
          <TabList>
            <Item key="accountGrouping">Single Source of Truth</Item>
          </TabList>
          <TabPanels>
            <Item key="accountGrouping">{renderAccountGrouping()}</Item>
          </TabPanels>
        </Tabs>
      </Flex>
    </Provider>
  );
}
