import {
  Button,
  Flex,
  View,
  Text,
  Dialog,
  Heading,
  Content,
  useDialogContainer,
  useAsyncList,
  TooltipTrigger,
  ActionButton,
  Tooltip,
  Item,
  ComboBox,
  TagGroup,
  SearchField,
  DialogContainer,
  AlertDialog,
  TextArea,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  error as ErrorToast,
  success as SuccessToast,
} from "@react/react-spectrum/Toast";
import ViewDetail from "@spectrum-icons/workflow/ViewDetail";
import Refresh from "@spectrum-icons/workflow/Refresh";
import Checkmark from "@spectrum-icons/workflow/Checkmark";
import Close from "@spectrum-icons/workflow/Close";
import PropTypes from "prop-types";
import Table from "../../Common/Table";
import { workflowApi } from "../../../api/workflowApi";
import { ViewWfTasksColumns } from "../../../constants/Columns";
import LoadingDialog from "../LoadingDialog";
import ViewTaskDetailsDialog from "./ViewTaskDetailsDialog";
import useUserProfile from "../../../context/user-context";
import { APPROVED, REJECTED, PENDING } from "../../../constants/Status";

export default function ViewWorkflowTasks({ details }) {
  const dialog = useDialogContainer();
  const [isSearchLoading, setSearchLoading] = useState("");
  const [isPageLoading, setPageLoading] = useState([]);
  const [colInfo, setColInfo] = useState(ViewWfTasksColumns());
  const [numProcessed, setNumProcessed] = useState("");
  const [totalTasks, setTotalTasks] = useState("");
  const [areColsSet, setAreColsSet] = useState(false);
  const [viewTaskDetails, setViewTaskDetails] = useState(false);
  const [viewTaskId, setViewTaskId] = useState("");
  const [isTaskReadOnly, setIsTaskReadOnly] = useState(false);
  const taskSearchResultSize = 10;
  const [fetchTasksRequest, setFetchTasksRequest] = useState({
    number_of_records: taskSearchResultSize,
  });
  const [searchedTaskId, setSearchedTaskId] = useState("");
  const [selectedTasks, setSelectedTasks] = useState(new Set([])); // keys in table which are selected
  const [rejectionReason, setRejectionReason] = useState("");
  const [inputRejectionReason, setInputRejectionReason] = useState(false);
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  // wf type combobox + taglist
  const [wfStatus, setWfStatus] = useState([]);
  const [selectedWfStatus, setSelectedWfStatus] = useState([]);
  const [wfStatusField, setWfStatusField] = useState({
    selectedKey: "",
    inputValue: "All",
  });

  useEffect(() => {
    // set workflow status picker options
    workflowApi
      .fetchDropdownOptions([{ field_name: "status" }], authState.accessToken)
      .then((data) => {
        setWfStatus(data);
      })
      .catch((error) => {
        ErrorToast(
          error.response?.data?.message || "Failed to fetch task statuses!",
          {
            timeout: 5000,
          }
        );
      });
  }, []);

  useEffect(() => {
    if (selectedWfStatus.length === 0) {
      setFetchTasksRequest({
        number_of_records: taskSearchResultSize,
        wf_id: details.wf_id,
        task_id: searchedTaskId,
      });
    } else {
      setFetchTasksRequest({
        ...fetchTasksRequest,
        status_list: selectedWfStatus.map((wfType) => wfType.id),
      });
    }
  }, [selectedWfStatus]);

  const list = useAsyncList({
    initialFilterText: "",
    async load({ filterText, cursor }) {
      let json = {
        results: [],
      };
      const request = cursor || fetchTasksRequest;
      if (filterText !== "") {
        setSearchLoading("loading");
        json = await workflowApi.fetchWorkflowTasks(
          request,
          authState.accessToken
        );
      }
      const results = json?.results.task_list || [];

      setSearchLoading("");
      return {
        items: results,
        cursor:
          results.length < taskSearchResultSize
            ? null
            : {
                ...fetchTasksRequest,
                last_task_id: results[results.length - 1]?.task_id,
              },
      };
    },
    getKey: (item) => item?.id,
  });

  useEffect(() => {
    list.setFilterText(fetchTasksRequest);
  }, [fetchTasksRequest]);

  const updateNumProcessed = () => {
    setPageLoading(true);
    workflowApi
      .fetchWorkflowTasks(
        { number_of_records: 1, wf_id: details.wf_id },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        setNumProcessed(data.results.processed_tasks);
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(error.response?.data?.message || "Failed to fetch tasks!", {
          timeout: 5000,
        });
      });
  };

  const handleBulkApproval = (selectedTaskIds) => {
    const wfTaskList = [...selectedTaskIds].map((taskId) => ({
      task_id: taskId,
      wf_id: details.wf_id,
    }));
    setPageLoading(true);
    workflowApi
      .approveTasks(
        {
          wf_and_task_id_list: wfTaskList,
          user_id: user?.userId,
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        const successfulApprovals = [];
        const unsuccessfulApprovals = [];

        Object.keys(data).forEach((wf) => {
          if (data[wf]) successfulApprovals.push(wf);
          else unsuccessfulApprovals.push(wf);
        });

        if (successfulApprovals.length > 0) {
          SuccessToast(
            `Successfully approved ${successfulApprovals.length} tasks(s)!`,
            {
              timeout: 5000,
            }
          );
        }
        if (unsuccessfulApprovals.length > 0) {
          ErrorToast(
            `Error approving ${unsuccessfulApprovals.length} tasks(s)`,
            {
              timeout: 5000,
            }
          );
        }
        updateNumProcessed();
        setFetchTasksRequest({ ...fetchTasksRequest });
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Failed to approve tasks!",
          {
            timeout: 5000,
          }
        );
      });
    setSelectedTasks(new Set([]));
  };

  const handleBulkRejection = (selectedTaskIds, rejectReason) => {
    const wfTaskList = [...selectedTaskIds].map((taskId) => ({
      task_id: taskId,
      wf_id: details.wf_id,
    }));
    setPageLoading(true);
    workflowApi
      .rejectTasks(
        {
          wf_and_task_id_list: wfTaskList,
          user_id: user?.userId,
          ...(rejectReason !== "" && { rejection_reason: rejectReason }),
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        const successfulRejections = [];
        const unsuccessfulRejections = [];

        Object.keys(data).forEach((wf) => {
          if (data[wf]) successfulRejections.push(wf);
          else unsuccessfulRejections.push(wf);
        });

        if (successfulRejections.length > 0) {
          SuccessToast(
            `Successfully rejected ${successfulRejections.length} tasks(s)!`,
            {
              timeout: 5000,
            }
          );
        }
        if (unsuccessfulRejections.length > 0) {
          ErrorToast(
            `Error rejecting ${unsuccessfulRejections.length} tasks(s)`,
            {
              timeout: 5000,
            }
          );
        }
        updateNumProcessed();
        setFetchTasksRequest({ ...fetchTasksRequest });
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(error.response?.data?.message || "Failed to reject tasks!", {
          timeout: 5000,
        });
      });
    setSelectedTasks(new Set([]));
  };

  const getData = () => {
    if (Object.keys(details).length > 0) {
      setPageLoading(true);
      workflowApi
        .fetchWorkflowTasks(
          { number_of_records: 1, wf_id: details.wf_id },
          authState.accessToken
        )
        .then((data) => {
          if (data.results.task_list.length > 0) {
            if (!areColsSet) {
              const cols = [];
              if (data.results.task_list[0].task_details === null) {
                // tasks don't have details, display comment instead
                cols.push({ name: "Comment", uid: "comments" });
              } else {
                const dynamicColumns = [];
                Object.keys(data.results.task_list[0].task_details).forEach(
                  (attr) => {
                    dynamicColumns.push({
                      uid: attr,
                      name:
                        attr.charAt(0).toUpperCase() +
                        attr.slice(1).replaceAll("_", " "),
                    });
                  }
                );
                cols.push(...dynamicColumns);
              }
              if (data.results.task_list[0].contains_details)
                cols.push({
                  name: "Details",
                  uid: "details",
                  defaultWidth: 90,
                });
              setColInfo([...colInfo, ...cols]);
              setAreColsSet(true);
            }
          }
          setNumProcessed(data.results.processed_tasks);
          setTotalTasks(data.results.total_tasks);
          setPageLoading(false);
        })
        .catch((error) => {
          setPageLoading(false);
          ErrorToast(
            error.response?.data?.message || "Failed to fetch tasks!",
            {
              timeout: 5000,
            }
          );
        });
    }
    setFetchTasksRequest({
      ...fetchTasksRequest,
      wf_id: details.wf_id,
      task_id: searchedTaskId,
    });
  };

  useEffect(() => {
    getData();
  }, [details]);

  const onComboboxInputChange = (value, setFieldFunc) => {
    setFieldFunc((prevState) => ({
      inputValue: value,
      selectedKey: value === "" ? null : prevState.selectedKey,
    }));
  };

  const renderCell = (colKey, row) => {
    let cellColor = "";
    if (row.status === APPROVED) cellColor = "green";
    if (row.status === REJECTED) cellColor = "red";

    let cellContents;
    if (Object.keys(row).includes(colKey)) {
      if (colKey === "comments") {
        if (row.status === REJECTED) {
          cellContents = `${row.comments} RejectionReason: ${row.rejection_reason}`;
        } else {
          cellContents = row.comments;
        }
        return (
          <span // eslint-disable-line jsx-a11y/no-static-element-interactions
            style={{ cursor: "text", WebkitUserSelect: "text" }}
            onPointerDown={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <div style={{ whiteSpace: "pre-line", color: cellColor }}>
              {cellContents}
            </div>
          </span>
        );
      }
      cellContents = row[colKey];
    } else if (colKey === "status" && row[colKey] === "Rejected") {
      cellContents = `${row[colKey]} (${row.rejection_reason})`;
    } else if (colKey === "details") {
      return (
        <TooltipTrigger>
          <ActionButton
            isQuiet
            onPress={() => {
              setViewTaskDetails(true);
              setViewTaskId(row.task_id);
              setIsTaskReadOnly(
                row.status === APPROVED || row.status === REJECTED
              );
            }}
          >
            <View>
              <ViewDetail color="informative" />
            </View>
          </ActionButton>
          <Tooltip>View Workflow Details</Tooltip>
        </TooltipTrigger>
      );
    } else {
      cellContents = row.task_details[colKey];
    }

    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text UNSAFE_style={{ color: cellColor }}>{cellContents}</Text>
      </span>
    );
  };

  return (
    <Dialog width="2000px">
      <LoadingDialog isOpen={isPageLoading} />
      <Content>
        <Flex direction="column">
          <View>
            <Flex>
              <View
                marginStart="0rem"
                flex="1"
                UNSAFE_style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "inherit",
                }}
              >
                <Heading level={3}>
                  Workflow Id:{" "}
                  <Text UNSAFE_style={{ fontWeight: "normal" }}>
                    {details.wf_id}
                  </Text>
                </Heading>
                <Heading level={3}>
                  Type:{" "}
                  <Text UNSAFE_style={{ fontWeight: "normal" }}>
                    {details.wf_type}
                  </Text>
                </Heading>
              </View>
              <View
                marginEnd="0rem"
                UNSAFE_style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "right",
                  alignItems: "end",
                }}
              >
                <Heading level={3}>
                  Comments:{" "}
                  <Text UNSAFE_style={{ fontWeight: "normal" }}>
                    {details.comments}
                  </Text>
                </Heading>
                <Heading level={3}>
                  Task Processing Status:{" "}
                  <Text UNSAFE_style={{ fontWeight: "normal" }}>
                    {`${numProcessed}/${totalTasks}`}
                  </Text>
                  <TooltipTrigger>
                    <ActionButton isQuiet onPress={() => getData()}>
                      <Refresh size="S" />
                    </ActionButton>
                    <Tooltip>Refresh Tasks</Tooltip>
                  </TooltipTrigger>
                </Heading>
              </View>
            </Flex>
          </View>

          {totalTasks > 0 && (
            <Flex gap="size-200">
              <Flex direction="column">
                <ComboBox
                  label="Status"
                  defaultItems={wfStatus}
                  selectedKey={wfStatusField.selectedKey}
                  inputValue={wfStatusField.inputValue}
                  onSelectionChange={(val) => {
                    if (val) {
                      setSelectedWfStatus([
                        ...selectedWfStatus,
                        wfStatus.find((type) => type.id === val),
                      ]);
                      setWfStatus(wfStatus.filter((type) => type.id !== val));
                      setWfStatusField({ ...wfStatusField, inputValue: "" });
                    }
                  }}
                  onInputChange={(val) =>
                    onComboboxInputChange(val, setWfStatusField)
                  }
                  width="200px"
                >
                  {(item) => <Item>{item.name}</Item>}
                </ComboBox>
                {selectedWfStatus.length > 0 && (
                  <View maxWidth="200px">
                    <TagGroup
                      onRemove={(val) => {
                        const selectedId = val.values().next().value;
                        setWfStatus([
                          ...wfStatus,
                          selectedWfStatus.find(
                            (type) => type.id === selectedId
                          ),
                        ]);
                        setSelectedWfStatus(
                          selectedWfStatus.filter(
                            (type) => type.id !== selectedId
                          )
                        );
                        setWfStatusField({
                          ...wfStatusField,
                          inputValue:
                            selectedWfStatus.length === 1 ? "All" : "",
                        });
                      }}
                      items={selectedWfStatus}
                      aria-label="Selected"
                      allowsRemoving
                    >
                      {(item) => (
                        <Item key={item.id} textValue={item.name}>
                          <Text>{item.name}</Text>
                        </Item>
                      )}
                    </TagGroup>
                  </View>
                )}
              </Flex>
              <Flex>
                <SearchField
                  label="Search"
                  placeholder="Search by task id"
                  onSubmit={(val) => {
                    setSearchedTaskId(val);
                    setFetchTasksRequest({
                      ...fetchTasksRequest,
                      task_id: val,
                    });
                  }}
                  onChange={setSearchedTaskId}
                  onClear={() => {
                    setSearchedTaskId("");
                    setFetchTasksRequest({ ...fetchTasksRequest, task_id: "" });
                  }}
                  width="250px"
                />
              </Flex>
            </Flex>
          )}

          {details.can_approve && totalTasks > 0 && (
            <Flex alignItems="end" marginStart="auto" gap="size-125">
              <Button
                variant="primary"
                isDisabled={selectedTasks.size === 0}
                onClick={() => {
                  const selectedTaskIds = [];
                  selectedTasks.forEach((taskKey) =>
                    selectedTaskIds.push(
                      list.items.find((obj) => obj.id === taskKey).task_id
                    )
                  );
                  handleBulkApproval(selectedTaskIds);
                }}
              >
                <Checkmark />
                <Text>Approve</Text>
              </Button>

              <Button
                variant="negative"
                isDisabled={selectedTasks.size === 0}
                onClick={() => setInputRejectionReason(true)}
              >
                <Close />
                <Text>Reject</Text>
              </Button>
            </Flex>
          )}

          {totalTasks > 0 ? (
            <Flex
              direction="column"
              gap="size-125"
              marginTop="size-300"
              marginBottom="size-300"
              UNSAFE_style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
              height={list.items > 0 ? "static-size-7000" : "static-size-6000"}
            >
              <Table
                columns={colInfo}
                rows={list.items}
                selectionMode={details.can_approve ? "multiple" : "none"}
                renderCell={renderCell}
                density="compact"
                loadingState={
                  isSearchLoading !== "" ? isSearchLoading : list.loadingState
                }
                onLoadMore={list.loadMore}
                selectedKeys={selectedTasks}
                onSelectionChange={(val) => {
                  if (val === "all") {
                    setSelectedTasks(
                      list.items
                        .filter((item) => item.status === "Pending")
                        .map((item) => item.id)
                    );
                  } else {
                    setSelectedTasks(val);
                  }
                }}
                disabledKeys={list.items
                  .filter((wf) => !(wf.status === PENDING))
                  .map((wf) => wf.id)}
              />
            </Flex>
          ) : (
            <Flex>
              <p>No pending tasks for this workflow</p>
            </Flex>
          )}

          <Flex alignItems="end" marginStart="auto">
            <Button
              variant="secondary"
              onPress={() => {
                setNumProcessed("");
                setTotalTasks("");
                list.items = [];
                dialog.dismiss();
              }}
            >
              Close
            </Button>
          </Flex>
        </Flex>

        <DialogContainer onDismiss={() => setViewTaskDetails(false)}>
          {viewTaskDetails && (
            <ViewTaskDetailsDialog
              taskId={viewTaskId}
              wfId={details.wf_id}
              canApprove={details.can_approve && !isTaskReadOnly}
              handleApproval={handleBulkApproval}
              handleRejection={handleBulkRejection}
            />
          )}
        </DialogContainer>
        <DialogContainer onDismiss={() => setInputRejectionReason(false)}>
          {inputRejectionReason && (
            <AlertDialog
              title="Reject request"
              variant="destructive"
              primaryActionLabel="Yes"
              cancelLabel="No"
              onPrimaryAction={() => {
                const selectedTaskIds = [];
                selectedTasks.forEach((taskKey) =>
                  selectedTaskIds.push(
                    list.items.find((obj) => obj.id === taskKey).task_id
                  )
                );
                handleBulkRejection(selectedTaskIds, rejectionReason);
              }}
            >
              <Flex direction="column" gap="size-150">
                <Text>
                  Are you sure you want to reject this request? Please confirm
                  by providing reason for rejecting the request.
                </Text>
                <TextArea
                  label="Reason"
                  width="100%"
                  value={rejectionReason}
                  onChange={setRejectionReason}
                />
              </Flex>
            </AlertDialog>
          )}
        </DialogContainer>
      </Content>
    </Dialog>
  );
}

ViewWorkflowTasks.propTypes = {
  details: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.objectOf(PropTypes.string),
      PropTypes.any,
    ])
  ).isRequired,
};
