import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  TextField,
  useDialogContainer,
} from "@adobe/react-spectrum";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import { hierarchyApi } from "../../../api/hierarchyApi";
import useUserProfile from "../../../context/user-context";

export default function CreateNewGTMHierarchy({ onClose, name }) {
  const dialog = useDialogContainer();
  const [hierarchyName, setHierarchyName] = useState(name);
  const nodeInfo = {};
  const resp = {};
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  const handleCreate = () => {
    nodeInfo.hierarchyName = hierarchyName;
    nodeInfo.userId = user?.userId;
    resp.data = nodeInfo;
    resp.action = "edit";
    hierarchyApi
      .createNewGTMHierarchy(nodeInfo, authState.accessToken)
      .then(() => {
        onClose(resp);
        setHierarchyName();
        dialog.dismiss();
      });
  };

  const handleCancel = () => {
    resp.action = "cancel";
    onClose(resp);
    setHierarchyName();
    dialog.dismiss();
  };

  return (
    <Dialog>
      <Heading>Create New Hierarchy Type</Heading>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            label="New GTM Hierarchy Name"
            defaultValue="GTM "
            value={hierarchyName}
            onChange={setHierarchyName}
            isRequired
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button onPress={handleCreate}>Save</Button>
        <Button onPress={handleCancel}>Cancel</Button>
      </ButtonGroup>
    </Dialog>
  );
}

CreateNewGTMHierarchy.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
