import {
  Tabs,
  Flex,
  TabList,
  TabPanels,
  Item,
  Provider,
  defaultTheme,
  Content,
  Form,
  TextField,
  ButtonGroup,
  Button,
  Text,
  Heading,
  Picker,
  TextArea,
} from "@adobe/react-spectrum";
import { error as ErrorToast } from "@react/react-spectrum/Toast";
import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { camAIApi } from "../../api/camAIApi";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import Table from "../../components/Common/Table";
import { MarketingSearchResponseColumns } from "../../constants/Columns";
import "./MarketingSearch.css";

export default function MarketingSearch() {
  const [searchQuery, setSearchQuery] = useState({
    account_name: "",
    street: "",
    // city: "",
    country: "",
    state: "",
    postalcode: "",
    domain: "",
    website: "",
  });
  const [fetchCount, setFetchCount] = useState(5);
  const [threshold, setThreshold] = useState("");
  const [sourceSystem, setSourceSystem] = useState("sfdc-corp");
  const [showingQueryAlert, setShowingQueryAlert] = useState(false);
  const [queryResult, setQueryResult] = useState([]);
  const [hasMatches, setHasMatches] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const responseColumns = MarketingSearchResponseColumns();
  const { authState } = useOktaAuth();

  const defaultScoringConfig = {
    account_name: {
      match_with: "account_name",
      enable_scoring: "y",
      weight: 30.0,
      threshold: 50,
    },
    domain: {
      match_with: "domain, website, web_domain",
      enable_scoring: "y",
      weight: 20.0,
      threshold: 80,
    },
    country: {
      match_with: "country",
      enable_scoring: "y",
      weight: 15.0,
      threshold: 90,
    },
    state: {
      match_with: "state",
      enable_scoring: "y",
      weight: 10.0,
      threshold: 90,
    },
    postalcode: {
      match_with: "postalcode",
      enable_scoring: "y",
      weight: 10.0,
      threshold: 90,
    },
    street: {
      match_with: "street",
      enable_scoring: "y",
      weight: 15.0,
      threshold: 60,
    },
  };
  const [scoringConfig, setScoringConfig] = useState(
    JSON.stringify(defaultScoringConfig, null, 2)
  );

  const handleMarketingSearchQuery = () => {
    setPageLoading(true);
    camAIApi
      .marketingSearch(
        {
          fetch_count: fetchCount,
          search_attributes: searchQuery,
          // filter_attributes: { source_system: sourceSystem },
          ...(sourceSystem !== "" && {
            filter_attributes: { source_system: sourceSystem },
          }),
          ...(threshold !== "" && { confidence_threshold: threshold }),
          ...(scoringConfig !== "" && {
            scoring_config: JSON.parse(scoringConfig),
          }),
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        if (Array.isArray(data)) {
          setHasMatches(true);
          setQueryResult(data.map((obj) => ({ ...obj, id: obj._id }))); // eslint-disable-line no-param-reassign, no-underscore-dangle
        } else if (
          typeof data === "object" &&
          Object.prototype.hasOwnProperty.call(data, "result") &&
          Object.prototype.hasOwnProperty.call(data, "reason")
        ) {
          setHasMatches(false);
          setQueryResult(`${data.result} - ${data.reason}`);
        } else {
          setHasMatches(false);
          setQueryResult(
            typeof data === "object" ? JSON.stringify(data) : data.toString()
          );
        }
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(error.response?.data?.message || "Error Querying Account!", {
          timeout: 5000,
        });
        setHasMatches(false);
        setQueryResult("Error querying, please try again");
      });
    setShowingQueryAlert(true);
  };

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (!Object.hasOwnProperty.call(row, "confidence_score"))
      cellStyle = { color: "black" };
    else {
      const confidenceScore = Number.isInteger(row.confidence_score)
        ? row.confidence_score
        : parseInt(row.confidence_score.replace("%", ""), 10);
      if (confidenceScore >= 90) cellStyle = { color: "green" };
      else if (confidenceScore >= 75) cellStyle = { color: "#d69702" };
      else cellStyle = { color: "red" };
    }

    if (colKey === "response")
      return (
        <span className="api-response">
          <span // eslint-disable-line jsx-a11y/no-static-element-interactions
            style={{ cursor: "text", WebkitUserSelect: "text" }}
            onPointerDown={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <Text UNSAFE_style={cellStyle}>{JSON.stringify(row)}</Text>;
          </span>
          {/* <Text UNSAFE_style={cellStyle}>{JSON.stringify(row)}</Text>; */}
        </span>
      );
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
      </span>
    );
    // <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  const renderAccountGrouping = () => (
    <>
      <Flex gap="10px" direction="column">
        <LoadingDialog isOpen={isPageLoading} />
        <Flex direction="column">
          <Content>
            <Flex gap="10px">
              <Form width="60%">
                <Flex gap="10px">
                  <TextField
                    autoFocus
                    label="Account Name"
                    value={searchQuery.account_name}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, account_name: value })
                    }
                    width="50%"
                    isRequired
                  />
                  <Picker
                    label="Source System"
                    onSelectionChange={setSourceSystem}
                    width="50%"
                    defaultSelectedKey="sfdc-corp"
                  >
                    <Item key="sfdc-corp">sfdc-corp</Item>
                    <Item key="d365">d365</Item>
                    <Item key="">sfdc-corp + d365</Item>
                  </Picker>
                </Flex>
                <Flex gap="10px">
                  <TextField
                    autoFocus
                    label="Street"
                    value={searchQuery.street}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, street: value })
                    }
                    width="50%"
                  />
                  <TextField
                    autoFocus
                    label="State"
                    value={searchQuery.state}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, state: value })
                    }
                    width="50%"
                  />
                </Flex>
                <Flex gap="10px">
                  <TextField
                    autoFocus
                    label="Country"
                    value={searchQuery.country}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, country: value })
                    }
                    width="50%"
                  />
                  <TextField
                    autoFocus
                    label="Postal Code"
                    value={searchQuery.postalcode}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, postalcode: value })
                    }
                    width="50%"
                  />
                </Flex>
                <Flex gap="10px">
                  <TextField
                    autoFocus
                    label="Domain"
                    value={searchQuery.domain}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, domain: value })
                    }
                    width="50%"
                  />
                  <TextField
                    autoFocus
                    label="Website"
                    value={searchQuery.website}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, website: value })
                    }
                    width="50%"
                  />
                </Flex>
                <Flex gap="10px">
                  <TextField
                    autoFocus
                    label="Fetch Count"
                    value={fetchCount}
                    onChange={setFetchCount}
                    width="50%"
                  />
                  <TextField
                    autoFocus
                    label="Threshold"
                    value={threshold}
                    onChange={setThreshold}
                    width="50%"
                  />
                </Flex>
              </Form>
              <Flex marginTop="5px" width="40%">
                <TextArea
                  autoFocus
                  label="Scoring Config"
                  value={scoringConfig}
                  onChange={setScoringConfig}
                  width="100%"
                  height="100%"
                  gap="10px"
                />
              </Flex>
            </Flex>
          </Content>
          <ButtonGroup marginTop={20}>
            <Button
              onPress={handleMarketingSearchQuery}
              isDisabled={searchQuery.account_name === ""}
            >
              Query
            </Button>
          </ButtonGroup>
        </Flex>
        {showingQueryAlert && (
          <Flex marginTop="size-325" direction="column">
            <Heading level={3} UNSAFE_style={{ textDecoration: "underline" }}>
              Result
            </Heading>
            {hasMatches && (
              <Table
                columns={responseColumns}
                rows={
                  Array.isArray(queryResult) ? queryResult : []
                  // Array.isArray(queryResult)
                  //   ? queryResult.map((obj) => ({ ...obj, id: obj._id })) // eslint-disable-line no-param-reassign, no-underscore-dangle
                  //   : []
                }
                renderCell={renderCell}
                selectionMode="none"
                density="compact"
                // overflowMode="wrap"
              />
            )}
          </Flex>
        )}
      </Flex>
    </>
  );

  return (
    <Provider theme={defaultTheme} colorScheme="light">
      <Flex margin="size-300" gap="size-150" wrap>
        <Tabs aria-label="Chat log density example" density="compact">
          <TabList>
            <Item key="accountGrouping">Marketing Search</Item>
          </TabList>
          <TabPanels>
            <Item key="accountGrouping">{renderAccountGrouping()}</Item>
          </TabPanels>
        </Tabs>
      </Flex>
    </Provider>
  );
}
