export const StandardizedAccountGeneralAttributes = () => {
  const ATTRIBUTES = [
    //   {
    //     title: "CAM ID",
    //     key: "camid",
    //   },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Street",
      key: "street",
    },
    {
      title: "Street Sup",
      key: "street_sup",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Postal Code",
      key: "postalcode",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "Domain",
      key: "domain",
    },
    // {
    //   title: "Duns Number",
    //   key: "duns_number",
    // },
    //   {
    //     title: "Global Parent ID",
    //     key: "gtm_global_parent_id",
    //   },
    //   {
    //     title: "Global Parent Name",
    //     key: "gtm_global_parent_name",
    //   },
    //   {
    //     title: "Global Sub ID",
    //     key: "gtm_global_sub_id",
    //   },
    //   {
    //     title: "Global Sub Name",
    //     key: "gtm_global_sub_name",
    //   },
    {
      title: "Created At",
      key: "sliding_created_at",
    },
    {
      title: "Last Updated",
      key: "sliding_last_updated",
    },
  ];

  return ATTRIBUTES;
};

export const SourceSystemAccountAttributes = () => {
  const COLUMNS = [
    //   {
    //     title: "Src Account ID",
    //     key: "account_id",
    //   },
    {
      title: "Account Name",
      key: "account_name",
    },
    //   {
    //     title: "Address",
    //     key: "address",
    //   },
    //   {
    //     title: "Source System",
    //     key: "source_system",
    //   },
    {
      title: "Industry",
      key: "crm_industry",
    },
    {
      title: "DX Sales Major Segment",
      key: "dx_sales_major_segment",
    },
    {
      title: "DX Sales Minor Segment",
      key: "dx_sales_minor_segment",
    },
    {
      title: "Dme Sales Major Segment",
      key: "dme_sales_major_segment",
    },
    {
      title: "Global Parent ID",
      key: "global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "global_sub_name",
    },
    {
      title: "Approval Status",
      key: "approval_status",
    },
    // {
    //   title: "Duplicate",
    //   key: "dup_flag",
    // },
    {
      title: "Created At",
      key: "sliding_created_at",
    },
    {
      title: "Last Updated",
      key: "sliding_last_updated",
    },
    //   {
    //     title: "Address",
    //     key: "address",
    //   },
  ];

  return COLUMNS;
};
