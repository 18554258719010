import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const queryAccount = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/query`, data);
  const responseData = await response.data;
  return responseData;
};

const marketingSearch = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/marketingsearch`, data);
  const responseData = await response.data;
  // return responseData[0].accounts;
  return responseData.search_results;
};

export const camAIApi = {
  queryAccount,
  marketingSearch,
};
