import SortableTree, {
  changeNodeAtPath,
  removeNodeAtPath,
  addNodeUnderParent,
} from "react-sortable-tree";
import React, { useEffect, useState } from "react";
import "./Tree.css";
import "react-sortable-tree/style.css";
import {
  Heading,
  DialogContainer,
  Text,
  MenuTrigger as RS3MenuTrigger,
  ActionButton as RS3ActionButton,
  Menu as RS3Menu,
  Item as RS3Item,
  AlertDialog,
  Flex,
} from "@adobe/react-spectrum";
import { error as ErrorToast } from "@react/react-spectrum/Toast";
import ArrowUp from "@spectrum-icons/workflow/ArrowUp";
import ArrowDown from "@spectrum-icons/workflow/ArrowDown";
import MoreIcon from "@spectrum-icons/workflow/MoreSmallListVert";
import PropTypes from "prop-types";
import NodeDialog from "../../Dialog/NodeDialog";
import CamSlidingPane from "../CamSlidingPane/CamSlidingPane";
import { hierarchyApi } from "../../../api/hierarchyApi";
import { StandardizedAccountsLabel } from "../../../constants/SlidingPaneLabels";
import GetORGDetailsDialog from "../../Dialog/HMS/GetORGDetailsDialog";
import GetSourceSystemDetailsDialog from "../../Dialog/HMS/GetSourceSystemDetailsDialog";

export default function Tree({
  data,
  heading,
  hierarchyType,
  workflow,
  nextNode,
  onChange,
  onNextChange,
  modifiedNodes,
}) {
  const [treeData, setTreeData] = useState(data);
  const [dialog, setDialog] = useState("");
  const [dunsId, setDunsId] = useState("");
  const [treeRowInfo, setTreeRowInfo] = useState();
  const [name, setName] = useState("");
  const [removedNodeIds, setRemovedNodeIds] = useState([]);
  const [comments, setComments] = useState("");
  const isReadOnly = false;
  const [openSlidingPane, setOpenSlidingPane] = useState(false);
  const [slidingPaneData, setSlidingPaneData] = useState({});
  const [slidingPaneLabels, setSlidingPaneLabels] = useState([]);
  const [callORGDetail, setCallORGDetail] = useState(false);
  const [camDetailsOpen, setCamDetailsOpen] = useState(false);
  const [sourceSystemDetailsOpen, setSourceSystemDetailsOpen] = useState(false);
  const [callSourceSystemDetail, setCallSourceSystemDetail] = useState(false);

  useEffect(() => {
    if (!isReadOnly) {
      onChange(removedNodeIds, treeData);
    }
  }, [treeData]);

  const placeholderRenderer = () => <Text> No data found </Text>;

  const handleCamDetailsClose = () => {
    setCamDetailsOpen(false);
    setCallORGDetail(false);
  };

  const handleSourceSystemDetailsClose = () => {
    setSourceSystemDetailsOpen(false);
    setCallSourceSystemDetail(false);
  };

  const handleOnPressMore = (rowInfo) => {
    setTreeRowInfo(rowInfo);
    setDunsId(
      rowInfo.node.subtitle ? rowInfo.node.subtitle : "System Generated"
    );
    setName(rowInfo?.node?.title);
    setComments(rowInfo?.node?.comments);
  };

  const handleOnMenuAction = (key, rowInfo) => {
    setDunsId(
      rowInfo.node.subtitle ? rowInfo.node.subtitle : "System Generated"
    );

    if (key === "add") {
      setDialog("Add");
    } else if (key === "edit") {
      setDialog("Edit");
    } else if (key === "remove") {
      setDialog("Remove");
    } else if (key === "view") {
      setDialog("View");
      hierarchyApi
        .getNodeDetails(dunsId)
        .then((nodeData) => {
          setSlidingPaneData(nodeData?.cam_attributes);
          setSlidingPaneLabels(StandardizedAccountsLabel());
          setOpenSlidingPane(!openSlidingPane);
        })
        .catch((error) => {
          console.log(error);
          ErrorToast(
            error.response?.nodeData?.message ||
              "Sorry something went wrong!!!",
            {
              timeout: 2000,
            }
          );
        });
    } else if (key === "ORG Details") {
      setCallORGDetail(true);
      setCamDetailsOpen(true);
    } else if (key === "Source System Details") {
      setCallSourceSystemDetail(true);
      setSourceSystemDetailsOpen(true);
    }
  };

  const generateProps = (rowInfo) => {
    const styleClasses = ["cam_tree_node"];
    let nodeProps = {};
    if (!isReadOnly && !rowInfo?.node?.isDelete) {
      if (workflow !== "Approval") {
        nodeProps = {
          buttons: [
            // rowInfo?.path?.length !== 3 ? (
            <RS3MenuTrigger>
              <RS3ActionButton
                isQuiet
                onPress={() => {
                  handleOnPressMore(rowInfo);
                }}
              >
                <MoreIcon />
              </RS3ActionButton>
              {/* <RS3Menu onAction={(key) => handleOnMenuAction(key)}>
                  {rowInfo?.path?.length === 1 && (
                    <RS3Item key="add">Add subsidiary</RS3Item>
                  )}
                  {rowInfo?.path?.length === 2 && (
                    <RS3Item key="edit">Edit subsidiary</RS3Item>
                  )}
                  {rowInfo?.node?.children?.length === 0 && (
                    <RS3Item key="remove">Remove subsidiary</RS3Item>
                  )}
                </RS3Menu> */}
              {/* </RS3MenuTrigger> */}
              {/* ) : ( */}
              {/* <RS3MenuTrigger> */}
              {/* <RS3ActionButton
                  isQuiet
                  onPress={() => {
                    handleOnPressMore(rowInfo);
                  }}
                >
                  <MoreIcon />
                </RS3ActionButton> */}
              <RS3Menu onAction={(key) => handleOnMenuAction(key, rowInfo)}>
                {/* <RS3Item key="view">View Details</RS3Item> */}
                {((rowInfo?.path?.length === 3 && hierarchyType === "GTM") ||
                  (hierarchyType === "ECH" &&
                    !rowInfo?.node?.isSourceSystem)) && (
                  <RS3Item key="ORG Details">View Org Entity Details</RS3Item>
                )}

                {(rowInfo?.path?.length === 4 &&
                  hierarchyType === "GTM Hierarchy") ||
                  (rowInfo?.node?.isSourceSystem && (
                    <RS3Item key="Source System Details">
                      View Source System Details
                    </RS3Item>
                  ))}
              </RS3Menu>
            </RS3MenuTrigger>,
            // ),
          ],
        };
      } else {
        nodeProps = {
          buttons: [
            rowInfo?.path?.length === 3 && (
              <RS3MenuTrigger>
                <RS3ActionButton
                  isQuiet
                  onPress={() => {
                    handleOnPressMore(rowInfo);
                  }}
                >
                  <MoreIcon />
                </RS3ActionButton>
                <RS3Menu onAction={(key) => handleOnMenuAction(key, rowInfo)}>
                  <RS3Item key="view">View Details</RS3Item>
                </RS3Menu>
              </RS3MenuTrigger>
            ),
          ],
        };
      }
    }
    if (
      !isReadOnly &&
      (rowInfo.node.modified || rowInfo.node.isAdd || rowInfo.node.isEdit) &&
      !rowInfo.node.isDelete
    ) {
      styleClasses.push("cam_tree_node_modified");
    }

    if (!isReadOnly && rowInfo.node.isChildModified) {
      styleClasses.push("cam_tree_node_child_modified");
    }

    if (!isReadOnly && rowInfo.node.isDelete) {
      styleClasses.push("cam_tree_node_deleted");
    }

    if (rowInfo?.path?.length === 3 && !isReadOnly) {
      nodeProps.title = (
        <div className="tooltip">
          {rowInfo?.node?.title}
          <div className="right">
            <div className="text-content">
              <Heading
                level={3}
              >{`${rowInfo?.node?.street} ${rowInfo?.node?.city}, ${rowInfo?.node?.state} ${rowInfo?.node?.country}`}</Heading>
            </div>

            <i></i>
          </div>
        </div>
      );
    }

    if (rowInfo.node?.isSourceSystem) styleClasses.push("source_system_node");

    nodeProps.className = styleClasses.join(" ");
    return nodeProps;
  };

  const isPathChanged = (prevPath, nextPath) => {
    for (let i = 0; i < prevPath.length - 1; i += 1) {
      if (prevPath[i] !== nextPath[i]) {
        return true;
      }
    }
    return false;
  };

  const handleOnMoveNode = (movedInfo) => {
    const { path, node, nextPath, prevPath } = movedInfo;

    if (isPathChanged(prevPath, nextPath)) {
      const tree = changeNodeAtPath({
        treeData,
        path,
        newNode: {
          ...node,
          modified: true,
        },
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      setTreeData([...tree]);
    }
  };

  const handleAddNode = (nodeName, nodeId) => {
    const { path } = treeRowInfo;
    if (nodeName) {
      setTreeData(
        addNodeUnderParent({
          treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          newNode: {
            title: nodeName,
            subtitle: nodeId,
            comments,
            children: [],
            isAdd: true,
          },
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData
      );
    }
  };

  const handleEditNode = (nodeName, nodeId) => {
    if (
      (nodeName && nodeName !== treeRowInfo.node.title) ||
      (comments && comments !== treeRowInfo.node.comments)
    ) {
      const { path, node } = treeRowInfo;
      const tree = changeNodeAtPath({
        treeData,
        path,
        newNode: {
          ...node,
          title: nodeName,
          subtitle: nodeId,
          comments,
          isEdit: true,
        },
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      setTreeData(tree);
    }
  };

  const handleRemoveNode = () => {
    const { path } = treeRowInfo;
    if (treeRowInfo && treeRowInfo.node.children.length === 0) {
      const newTree = removeNodeAtPath({
        treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      setTreeData(newTree);
      /* eslint no-underscore-dangle: 0 */
      setRemovedNodeIds(removedNodeIds.push(treeRowInfo.node._id));
      // removedNodeIds.push(treeRowInfo.node._id);
    }
  };

  // const handleCanDrag = (nodeEvent) => {
  //   if (!isReadOnly && workflow !== "Approval") {
  //     if (hierarchyType?.toUpperCase()?.includes("GTM")) {
  //       if (nodeEvent?.path?.length === 1 || nodeEvent?.path?.length === 2 || nodeEvent?.path?.length === 4) {
  //         return false;
  //       }
  //       return true;
  //     }
  //     if (nodeEvent?.path?.length === 1) {
  //       return false;
  //     }
  //     if (nodeEvent?.node?.isSourceSystem) {
  //       return false;
  //     }
  //     return true;
  //   }
  //   return false;
  // };

  // const handleCanDrop = (nodeEvent) => {
  //   if (!isReadOnly) {
  //     if (hierarchyType?.toUpperCase()?.includes("GTM")) {
  //       if (nodeEvent?.nextPath?.length === 3) {
  //         return true;
  //       }
  //       return false;
  //     }
  //     if (nodeEvent?.nextPath?.length === 1) {
  //       return false;
  //     }
  //     if (nodeEvent?.nextParent?.isSourceSystem) {
  //       return false;
  //     }
  //     return true;
  //   }
  //   return false;
  // };

  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery && node._id === searchQuery;

  return (
    <div className="tree">
      <Flex alignItems="center" justifyContent="space-between">
        <Heading level={2}>{heading}</Heading>
        {!isReadOnly && modifiedNodes && modifiedNodes.length !== 0 && (
          <div>
            <RS3ActionButton onPress={() => onNextChange("prev")} isQuiet>
              <ArrowUp />
            </RS3ActionButton>
            <RS3ActionButton onPress={() => onNextChange("next")} isQuiet>
              <ArrowDown />
            </RS3ActionButton>
          </div>
        )}
      </Flex>
      <SortableTree
        treeData={treeData}
        onChange={setTreeData}
        generateNodeProps={(rowInfo) => generateProps(rowInfo)}
        placeholderRenderer={placeholderRenderer}
        onMoveNode={handleOnMoveNode}
        // canDrag={handleCanDrag}
        // canDrop={handleCanDrop}
        canDrag={false}
        canDrop={false}
        searchMethod={customSearchMethod}
        searchQuery={nextNode}
        searchFocusOffset={0}
      />

      <DialogContainer onDismiss={() => setDialog(null)}>
        {dialog === "Edit" && (
          <NodeDialog
            onClose={handleEditNode}
            nodeName={name}
            nodeId={dunsId}
            action={dialog}
            nodeComments={comments}
          />
        )}
        {dialog === "Add" && (
          <NodeDialog
            onClose={handleAddNode}
            action={dialog}
            nodeComments={comments}
          />
        )}

        {dialog === "Remove" && (
          <AlertDialog
            title="Remove subsidiary"
            variant="destructive"
            primaryActionLabel="Remove"
            cancelLabel="Cancel"
            onPrimaryAction={handleRemoveNode}
          >
            This will remove the selected subsidiary. Do you want to continue?
          </AlertDialog>
        )}

        {dialog === "View" && openSlidingPane && (
          <CamSlidingPane
            isPaneOpenFlag={openSlidingPane}
            handleOpenDialog={() => setOpenSlidingPane(!openSlidingPane)}
            data={slidingPaneData}
            labels={slidingPaneLabels}
            paneTitle={slidingPaneData["Account Name"]}
          />
        )}
      </DialogContainer>
      <GetORGDetailsDialog
        open={camDetailsOpen}
        onClose={handleCamDetailsClose}
        orgId={dunsId}
        isCalled={callORGDetail}
      ></GetORGDetailsDialog>
      <GetSourceSystemDetailsDialog
        open={sourceSystemDetailsOpen}
        onClose={handleSourceSystemDetailsClose}
        accountId={dunsId}
        isCalled={callSourceSystemDetail}
      ></GetSourceSystemDetailsDialog>
    </div>
  );
}

Tree.defaultProps = {
  hierarchyType: "",
  onChange: () => {},
};

Tree.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array,
        PropTypes.arrayOf(PropTypes.object),
      ])
    )
  ).isRequired,
  heading: PropTypes.string.isRequired,
  hierarchyType: PropTypes.string,
  modifiedNodes: PropTypes.arrayOf(PropTypes.number).isRequired,
  nextNode: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onNextChange: PropTypes.func.isRequired,
  workflow: PropTypes.string.isRequired,
};
