import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  useDialogContainer,
  ActionButton,
  Text,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { error as ErrorToast } from "@react/react-spectrum/Toast";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import { hierarchyApi } from "../../../api/hierarchyApi";
import Table from "../../Common/Table";

const COLUMNS = [
  {
    uid: "startTime",
    name: "Change Time",
    defaultWidth: 140,
  },
  {
    uid: "wfId",
    name: "Workflow ID",
  },
  {
    uid: "userId",
    name: "Modified By",
  },
  {
    uid: "parentId",
    name: "Parent Id",
  },
  {
    uid: "parentName",
    name: "Parent Name",
    defaultWidth: 170,
  },
  {
    uid: "revert",
    name: "Revert",
  },
];

export default function GetNodeHistoryDialog({ onClose, dunsId, onRevert }) {
  const dialog = useDialogContainer();
  const [nodeHistory, setNodeHistory] = useState();
  const { authState } = useOktaAuth();

  useEffect(() => {
    hierarchyApi
      .getNodeHistory(dunsId, authState.accessToken)
      .then((data) => {
        setNodeHistory(data);
      })
      .catch((error) => {
        console.log(error);
        ErrorToast("Error retrieving node history", {
          timeout: 5000,
        });
      });
  }, [dunsId]);

  const handleCancel = () => {
    onClose();
    dialog.dismiss();
  };

  const handleRevert = (row) => {
    onRevert(row);
    dialog.dismiss();
  };

  const renderRevertButton = (row) => (
    <ActionButton onPress={() => handleRevert(row)}>Revert</ActionButton>
  );

  const renderCell = (colKey, row) => {
    if (colKey === "revert" && !row.endTime.startsWith("2099"))
      return renderRevertButton(row);
    if (colKey === "parentId")
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{ cursor: "text", WebkitUserSelect: "text" }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>{row.hierarchy[0].subtitle}</Text>
        </span>
      );
    if (colKey === "parentName")
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{ cursor: "text", WebkitUserSelect: "text" }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>{row.hierarchy[0].title}</Text>
        </span>
      );
    if (colKey === "startTime") {
      if (row.endTime.startsWith("2099")) {
        return <span>Current</span>;
      }
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{ cursor: "text", WebkitUserSelect: "text" }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>{row[colKey]}</Text>
        </span>
      );
    }
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text>{row[colKey]}</Text>
      </span>
    );
  };

  return (
    <Dialog size="L" width="1000px">
      <Heading>Node History For {dunsId}</Heading>
      <Divider />
      <Content>
        <Table
          columns={COLUMNS}
          rows={nodeHistory}
          renderCell={renderCell}
          selectionMode="none"
          density="compact"
        />
      </Content>
      <ButtonGroup>
        <Button onPress={handleCancel}>Cancel</Button>
      </ButtonGroup>
    </Dialog>
  );
}

GetNodeHistoryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  dunsId: PropTypes.string.isRequired,
  onRevert: PropTypes.func.isRequired,
};
