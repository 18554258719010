export const StandardizedAccountsColumn = () => {
  const COLUMNS = [
    {
      name: "CAM Id",
      uid: "camid",
      width: 195,
    },
    {
      name: "Account Name",
      uid: "account_name",
      minWidth: 150,
      width: 200,
    },
    {
      name: "Address",
      uid: "address_key",
      minWidth: 100,
      width: 300,
    },
    {
      name: "Global Parent Name",
      uid: "gtm_global_parent_name",
      width: 200,
    },
    {
      name: "Global Sub ID",
      uid: "gtm_global_sub_id",
      width: 195,
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
      width: 150,
    },
    {
      name: "Duns Id",
      uid: "duns_number",
      width: 150,
    },
  ];

  return COLUMNS;
};

export const OrgEntityAccountsColumn = () => {
  const COLUMNS = [
    {
      name: "ORG Id",
      uid: "org_entity_id",
      width: 195,
    },
    {
      name: "Account Name",
      uid: "account_name",
      minWidth: 150,
      width: 200,
    },
    {
      name: "Address",
      uid: "address_key",
      minWidth: 100,
      width: 300,
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
      width: 150,
    },
    {
      name: "Duns Id",
      uid: "duns_number",
      width: 150,
    },
    // {
    //   name: "Manage",
    //   uid: "manage",
    //   defaultWidth: 100,
    // },
  ];

  return COLUMNS;
};

export const OrgEntitySourceSystemAccountsColumn = () => {
  const COLUMNS = [
    {
      name: "Src Account Id",
      uid: "account_id",
      width: 195,
    },
    {
      name: "Account Name",
      uid: "account_name",
      minWidth: 150,
      width: 200,
    },
    {
      name: "Address",
      uid: "address_key",
      minWidth: 100,
      width: 300,
    },
    {
      name: "Source System",
      uid: "source_system",
      width: 120,
      sortable: false,
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
      width: 148,
    },
  ];

  return COLUMNS;
};

export const GoldenRecordSourceSystemAccountsColumn = () => {
  const COLUMNS = [
    {
      name: "Src Account Id",
      uid: "account_id",
      defaultWidth: 170,
    },
    {
      name: "Account Name",
      uid: "account_name",
      defaultWidth: 200,
    },
    {
      name: "Address",
      uid: "address",
      defaultWidth: 300,
    },
    {
      name: "Source System",
      uid: "source_system",
      defaultWidth: 140,
    },
    {
      name: "Created Date",
      uid: "createddatetime",
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
    },
    {
      name: "Last Updated By",
      uid: "modifiedby",
    },
  ];

  return COLUMNS;
};

export const SourceSystemAccountsColumn = () => {
  const COLUMNS = [
    {
      name: "Src Account Id",
      uid: "account_id",
      width: 195,
    },
    {
      name: "Account Name",
      uid: "account_name",
      minWidth: 150,
      width: 200,
    },
    {
      name: "Address",
      uid: "address_key",
      minWidth: 100,
      width: 300,
    },
    {
      name: "Source System",
      uid: "source_system",
      width: 120,
      sortable: false,
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
      width: 148,
    },
    {
      name: "Account Relationship",
      uid: "account_relationship",
      width: 110,
    },
  ];

  return COLUMNS;
};

export const AccountRelationshipColumn = () => {
  const COLUMNS = [
    {
      name: "Src Account Id",
      uid: "primary_account_id",
      width: 200,
    },
    {
      name: "Rel Account Id",
      uid: "related_account_id",
      minWidth: 150,
      width: 180,
    },
    {
      name: "Source System",
      uid: "primary_source_system",
      width: 180,
    },
    {
      name: "Rel Source System",
      uid: "related_source_system",
      width: 180,
    },
    {
      name: "Reason",
      uid: "relationship_reason",
      width: 200,
    },
    {
      name: "Status",
      uid: "active_status",
      width: 160,
    },
    {
      name: "Note",
      uid: "notes",
      width: 200,
    },
    {
      name: "Valid From",
      uid: "valid_from",
      width: 200,
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
      width: 250,
    },
  ];

  return COLUMNS;
};

export const GroupListColumn = () => {
  const COLUMNS = [
    { name: "Group Name", uid: "groupName" },
    { name: "Group Members", uid: "groupMembers" },
    { name: "CAM Functions", uid: "camFunctions" },
    { name: "Created By", uid: "createdBy" },
    { name: "Modified By", uid: "modifiedBy" },
    { name: "Subscribe", uid: "actions" },
  ];

  return COLUMNS;
};

export const ManageGroupsColumn = () => {
  const COLUMNS = [
    { name: "Group Name", uid: "groupName" },
    { name: "Group Members", uid: "groupMembers" },
    { name: "CAM Functions", uid: "camFunctions" },
    { name: "Created By", uid: "createdBy" },
    { name: "Modified By", uid: "modifiedBy" },
    { name: "Manage", uid: "manage" },
  ];

  return COLUMNS;
};

export const RecievedPendingRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Name", uid: "requestedGroup" },
    { name: "Type of Request", uid: "typeOfRequest" },
    { name: "Requested By", uid: "requestedBy" },
    { name: "Requested reason", uid: "requestedBusinessReason" },
    { name: "Requested Date", uid: "createdDateTime" },
    { name: "Status", uid: "status" },
    { name: "Actions", uid: "actions" },
  ];

  return COLUMNS;
};

export const RecievedApprovedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Name", uid: "requestedGroup" },
    { name: "Type of Request", uid: "typeOfRequest" },
    { name: "Requested By", uid: "requestedBy" },
    { name: "Status", uid: "status" },
    { name: "Requested reason", uid: "requestedBusinessReason" },
    { name: "Requested Date", uid: "createdDateTime" },
  ];

  return COLUMNS;
};

export const RecievedRejectedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Name", uid: "requestedGroup" },
    { name: "Type of Request", uid: "typeOfRequest" },
    { name: "Requested By", uid: "requestedBy" },
    { name: "Status", uid: "status" },
    { name: "Requested reason", uid: "requestedBusinessReason" },
    { name: "Rejection reason", uid: "requestStatusReason" },
    { name: "Requested Date", uid: "createdDateTime" },
  ];

  return COLUMNS;
};

export const SubmittedRejectedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Name", uid: "requestedGroup" },
    { name: "Type of Request", uid: "typeOfRequest" },
    { name: "Requested By", uid: "requestedBy" },
    { name: "Requested Date", uid: "createdDateTime" },
    { name: "Business reason", uid: "requestedBusinessReason" },
    { name: "Rejection reason", uid: "requestStatusReason" },
    { name: "Status", uid: "status" },
  ];

  return COLUMNS;
};

export const SubmittedApprovedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Name", uid: "requestedGroup" },
    { name: "Type of Request", uid: "typeOfRequest" },
    { name: "Requested By", uid: "requestedBy" },
    { name: "Requested Date", uid: "createdDateTime" },
    { name: "Business reason", uid: "requestedBusinessReason" },
    { name: "Status", uid: "status" },
  ];

  return COLUMNS;
};

export const EAMColumns = () => {
  const COLUMNS = [
    {
      name: "Account id",
      uid: "account_id",
      minWidth: 250,
    },
    {
      name: "Account name",
      uid: "account_name",
    },
    {
      name: "Source system",
      uid: "source_system",
      maxWidth: 120,
    },
    {
      name: "Address",
      uid: "address_key",
    },
  ];
  return COLUMNS;
};

export const EAMSearchSourceAccountColumns = () => {
  const COLUMNS = [
    {
      name: "Account id",
      uid: "account_id",
    },
    {
      name: "Account name",
      uid: "account_name",
    },
    {
      name: "Source system",
      uid: "source_system",
      maxWidth: 120,
    },
    {
      name: "Address",
      uid: "address_key",
    },
    {
      name: "Select",
      uid: "actions",
      maxWidth: 50,
    },
  ];
  return COLUMNS;
};

export const EAMConfirmChangesColumns = () => {
  const COLUMNS = [
    {
      name: "Account id",
      uid: "account_id",
      minWidth: 280,
    },
    {
      name: "Source system",
      uid: "source_system",
      maxWidth: 120,
    },
    {
      name: "Account name",
      uid: "account_name",
    },
  ];

  return COLUMNS;
};

export const EAMSplitSyncSetColumns = () => {
  const COLUMNS = [
    {
      name: "Account id",
      uid: "account_id",
      minWidth: 250,
    },
    {
      name: "Account name",
      uid: "account_name",
    },
    {
      name: "Source system",
      uid: "source_system",
      maxWidth: 120,
    },
    {
      name: "Address",
      uid: "address_key",
    },
    {
      name: "Actions",
      uid: "actions",
      maxWidth: 50,
    },
  ];

  return COLUMNS;
};

export const AccountGroupingQueryMatchingAccounts = () => {
  const COLUMNS = [
    {
      uid: "camid",
      name: "CAMID",
      defaultWidth: 120,
    },
    {
      uid: "account_name",
      name: "Account Name",
      defaultWidth: 150,
    },
    {
      uid: "street",
      name: "Street",
      defaultWidth: 150,
    },
    {
      uid: "city",
      name: "City",
      defaultWidth: 90,
    },
    {
      uid: "state",
      name: "State",
      defaultWidth: 80,
    },
    {
      uid: "country",
      name: "Country",
      defaultWidth: 100,
    },
    {
      uid: "percentage_match",
      name: "Match %",
      defaultWidth: 90,
    },
    {
      uid: "confidence_score",
      name: "Confidence Score",
      defaultWidth: 100,
    },
    {
      uid: "reason",
      name: "Reason",
    },
    {
      uid: "duns",
      name: "DUNS",
      defaultWidth: 85,
    },
  ];

  return COLUMNS;
};

export const MarketingSearchResponseColumns = () => {
  const COLUMNS = [
    // {
    //   uid: "camid",
    //   name: "CAMID",
    //   defaultWidth: 120,
    // },
    {
      uid: "account_id",
      name: "Account ID",
      defaultWidth: 160,
    },
    {
      uid: "account_name",
      name: "Account Name",
      defaultWidth: 150,
    },
    // {
    //   uid: "street",
    //   name: "Street",
    //   defaultWidth: 130,
    // },
    // {
    //   uid: "city",
    //   name: "City",
    //   defaultWidth: 90,
    // },
    // {
    //   uid: "state",
    //   name: "State",
    //   defaultWidth: 70,
    // },
    // {
    //   uid: "country",
    //   name: "Country",
    //   defaultWidth: 80,
    // },
    // {
    //   uid: "postalcode",
    //   name: "Postal Code",
    //   defaultWidth: 80,
    // },
    // {
    //   uid: "source_system",
    //   name: "Source System",
    //   defaultWidth: 80,
    // },
    // {
    //   uid: "domain",
    //   name: "Domain",
    //   defaultWidth: 100,
    // },
    {
      uid: "confidence_score",
      name: "Score",
      defaultWidth: 90,
    },
    // {
    //   uid: "reason",
    //   name: "Reason",
    //   defaultWidth: 170,
    // },
    {
      uid: "response",
      name: "Full API Response",
    },
  ];

  return COLUMNS;
};

export const HMSWorkflowColumns = () => {
  const COLUMNS = [
    {
      uid: "wf_id",
      name: "ID",
      defaultWidth: 110,
    },
    {
      uid: "wf_type",
      name: "Type",
      defaultWidth: 100,
    },
    {
      uid: "wf_subtype",
      name: "Subtype",
      defaultWidth: 140,
    },
    {
      uid: "created_by",
      name: "Requestor",
      defaultWidth: 110,
    },
    {
      uid: "created_date_time",
      name: "Requested Date",
      defaultWidth: 140,
    },
    {
      uid: "comments",
      name: "Comments",
      // defaultWidth: 300,
    },
    {
      uid: "status",
      name: "Status",
      defaultWidth: 85,
    },
    {
      uid: "details",
      name: "Details",
      defaultWidth: 90,
    },
  ];

  return COLUMNS;
};

export const OrphanAccountsColumn = () => {
  const COLUMNS = [
    {
      uid: "org_entity_id",
      name: "ORG ID",
    },
    {
      uid: "name",
      name: "Account Name",
    },
    {
      uid: "duns_number",
      name: "Duns",
    },
    {
      uid: "street",
      name: "Street",
    },
    {
      uid: "city",
      name: "City",
    },
    {
      uid: "country",
      name: "Country",
      defaultWidth: 100,
    },
    {
      uid: "state",
      name: "State",
      defaultWidth: 100,
    },
    {
      uid: "modifieddatetime",
      name: "Modified",
    },
    // {
    //   uid: "action",
    //   name: "Actions",
    //   defaultWidth: 90,
    // },
  ];

  return COLUMNS;
};

export const LAGLookup = () => {
  const COLUMNS = [
    {
      name: "LAGID",
      uid: "lag_id",
    },
    {
      name: "CAMID",
      uid: "cam_id",
      // maxWidth: 120,
    },
    {
      name: "ECCID",
      uid: "ecc_id",
    },
    {
      name: "Account Name",
      uid: "account_name",
    },
    {
      name: "Country",
      uid: "country",
    },
    {
      name: "Domain",
      uid: "domain",
    },
    {
      name: "Source System",
      uid: "source_system",
    },
    {
      name: "Group",
      uid: "group_key",
      maxWidth: 85,
    },
    {
      name: "Related Group",
      uid: "related_group_key",
    },
    {
      name: "Description",
      uid: "group_description",
    },
    {
      name: "Override Details",
      uid: "overridden",
    },
  ];

  return COLUMNS;
};

export const ViewWfTasksColumns = () => {
  const COLUMNS = [
    {
      uid: "task_id",
      name: "Task",
      defaultWidth: 30,
    },
    {
      uid: "status",
      name: "Status",
      defaultWidth: 90,
    },
    // {
    //   uid: "rejection_reason",
    //   name: "Rejection Reason",
    // },
  ];

  return COLUMNS;
};

export const GoldenRecordsColumn = () => {
  const COLUMNS = [
    {
      name: "ORG Id",
      uid: "org_entity_id",
      defaultWidth: 150,
    },
    {
      name: "Account Name",
      uid: "org_name_1",
      defaultWidth: 200,
    },
    {
      name: "Address",
      uid: "org_address",
      defaultWidth: 200,
    },
    {
      name: "Duns Id",
      uid: "duns_number",
      defaultWidth: 120,
    },
    {
      name: "Website",
      uid: "website",
    },
    {
      name: "Last update",
      uid: "modifieddatetime",
      defaultWidth: 150,
    },
  ];

  return COLUMNS;
};

export const LAGCreateRule = () => {
  const COLUMNS = [
    {
      name: "Field",
      uid: "field_name",
    },
    {
      name: "Value",
      uid: "value",
    },
    {
      name: "Include Condition",
      uid: "include",
    },
    {
      name: "Exclude Condition",
      uid: "exclude",
    },
    {
      name: "Partition",
      uid: "partition_value",
    },
    {
      name: "Modify Field",
      uid: "modify",
    },
  ];

  return COLUMNS;
};

export const ManualReviewAccountsColumn = () => {
  const COLUMNS = [
    {
      name: "ORG Id",
      uid: "org_entity_id",
      defaultWidth: 150,
    },
    {
      name: "Account Name",
      uid: "account_name",
      defaultWidth: 200,
    },
    {
      name: "Address",
      uid: "address",
      defaultWidth: 250,
    },
    {
      name: "Duns Id",
      uid: "duns_number",
      defaultWidth: 120,
    },
    {
      name: "Website",
      uid: "website",
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
      defaultWidth: 150,
    },
  ];

  return COLUMNS;
};

export const LAGPreviewRule = () => {
  const COLUMNS = [
    {
      name: "ECCID",
      uid: "ecc_id",
    },
    {
      name: "Account Name",
      uid: "account_name",
      defaultWidth: 200,
    },
    {
      name: "Address",
      uid: "address",
      defaultWidth: 250,
    },
    {
      name: "Duns Id",
      uid: "duns_number",
      defaultWidth: 120,
    },
    {
      name: "Website",
      uid: "website",
    },
    {
      name: "Last Update",
      uid: "modifieddatetime",
      defaultWidth: 150,
      minWidth: 250,
    },
    {
      name: "Source System",
      uid: "source_system",
    },
  ];

  return COLUMNS;
};

export const LAGOverrideRule = () => {
  const COLUMNS = [
    {
      name: "LAGID",
      uid: "lag_id",
      maxWidth: 100,
    },
    {
      name: "Group",
      uid: "group_key",
      maxWidth: 90,
    },
    {
      name: "Related Group",
      uid: "related_group_key",
    },
    {
      name: "Description",
      uid: "group_description",
    },
  ];

  return COLUMNS;
};

export const LAGSubmittedRejectedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Key", uid: "group_key", maxWidth: 110 },
    { name: "Group Description", uid: "group_description" },
    { name: "Requested By", uid: "requested_by" },
    { name: "Requested Date", uid: "created_date_time" },
    { name: "Rejection reason", uid: "rejection_reason" },
    { name: "Status", uid: "status", maxWidth: 90 },
  ];

  return COLUMNS;
};

export const LAGSubmittedApprovedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Key", uid: "group_key", maxWidth: 110 },
    { name: "Group Description", uid: "group_description" },
    { name: "Requested By", uid: "requested_by" },
    { name: "Effective Date", uid: "effective_date" },
    { name: "Current Environment", uid: "environment" },
    { name: "Status", uid: "status", maxWidth: 90 },
    { name: "Promote", uid: "promote", maxWidth: 100 },
  ];

  return COLUMNS;
};

export const LAGSubmittedPendingRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Key", uid: "group_key", maxWidth: 110 },
    { name: "Group Description", uid: "group_description" },
    { name: "Requested By", uid: "requested_by" },
    { name: "Requested Date", uid: "created_date_time" },
    { name: "Effective Date in Stage", uid: "effective_date" },
    { name: "Status", uid: "status", maxWidth: 90 },
  ];

  return COLUMNS;
};

export const LAGRecievedPendingRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Key", uid: "group_key", maxWidth: 110 },
    { name: "Group Description", uid: "group_description" },
    { name: "Requested By", uid: "requested_by" },
    { name: "Requested Date", uid: "created_date_time" },
    { name: "Effective Date in Stage", uid: "effective_date" },
    { name: "Status", uid: "status", maxWidth: 90 },
    { name: "Approve/Reject", uid: "approve_reject", maxWidth: 140 },
  ];

  return COLUMNS;
};

export const LAGRecievedApprovedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Key", uid: "group_key", maxWidth: 110 },
    { name: "Group Description", uid: "group_description" },
    { name: "Requested By", uid: "requested_by" },
    { name: "Requested Date", uid: "created_date_time" },
    { name: "Effective Date in Stage", uid: "effective_date" },
    { name: "Status", uid: "status", maxWidth: 90 },
    { name: "View Details", uid: "view", maxWidth: 120 },
  ];

  return COLUMNS;
};

export const LAGRecievedRejectedRequestColumns = () => {
  const COLUMNS = [
    { name: "Group Key", uid: "group_key", maxWidth: 110 },
    { name: "Group Description", uid: "group_description" },
    { name: "Requested By", uid: "requested_by" },
    { name: "Requested Date", uid: "created_date_time" },
    { name: "Rejection reason", uid: "rejection_reason" },
    { name: "Effective Date in Stage", uid: "effective_date" },
    { name: "Status", uid: "status", maxWidth: 90 },
    { name: "View Details", uid: "view", maxWidth: 120 },
  ];

  return COLUMNS;
};

export const LAGViewRule = () => {
  const COLUMNS = [
    {
      name: "Field",
      uid: "field_name",
    },
    {
      name: "Value",
      uid: "search_keyword",
    },
    {
      name: "Include Condition",
      uid: "include",
    },
    {
      name: "Exclude Condition",
      uid: "exclude",
    },
  ];

  return COLUMNS;
};
