import React from "react";
import PropTypes from "prop-types";
import {
  useDragAndDrop,
  TableView,
  TableHeader,
  TableBody,
  Row,
  Cell,
  Column,
} from "@adobe/react-spectrum";

export default function BidirectionalDnDTableView({
  list,
  columns,
  renderCell,
  disabledKeys,
}) {
  const { dragAndDropHooks } = useDragAndDrop({
    acceptedDragTypes: ["custom-app-type-bidirectional"],
    // Only allow move operations
    getAllowedDropOperations: () => ["move"],
    getItems(keys) {
      return [...keys].map((key) => {
        const item = list.getItem(key);
        // Setup the drag types and associated info for each dragged item.
        return {
          "custom-app-type-bidirectional": JSON.stringify(item),
          "text/plain": item.name,
        };
      });
    },
    onInsert: async (e) => {
      const { items, target } = e;
      const processedItems = await Promise.all(
        items.map(async (item) =>
          JSON.parse(await item.getText("custom-app-type-bidirectional"))
        )
      );
      if (target.dropPosition === "before") {
        list.insertBefore(target.key, ...processedItems);
      } else if (target.dropPosition === "after") {
        list.insertAfter(target.key, ...processedItems);
      }
    },
    onReorder: async (e) => {
      const { keys, target } = e;

      if (target.dropPosition === "before") {
        list.moveBefore(target.key, [...keys]);
      } else if (target.dropPosition === "after") {
        list.moveAfter(target.key, [...keys]);
      }
    },
    onRootDrop: async (e) => {
      const { items } = e;
      const processedItems = await Promise.all(
        items.map(async (item) =>
          JSON.parse(await item.getText("custom-app-type-bidirectional"))
        )
      );
      list.append(...processedItems);
    },
    onDragEnd: (e) => {
      const { dropOperation, keys, isInternal } = e;
      // Only remove the dragged items if they aren't dropped inside the source list
      if (dropOperation === "move" && !isInternal) {
        list.remove(...keys);
      }
    },
  });

  return (
    <TableView
      flex
      // aria-label={props['aria-label']}
      selectionMode="multiple"
      disabledKeys={disabledKeys}
      // width="size-8000"
      // height="size-3600"
      dragAndDropHooks={dragAndDropHooks}
      marginTop="1rem"
    >
      <TableHeader columns={columns}>
        {(column) => (
          <Column
            key={column.id}
            // align={column.id === 'date' ? 'end' : 'start'}
            allowsResizing
          >
            {column.name}
          </Column>
        )}
      </TableHeader>
      <TableBody items={list.items}>
        {(item) => (
          <Row
            key={
              item.id || item.requestId || item.camid || item.relationship_id
            }
          >
            {/* {(columnKey) => <Cell>{item[columnKey]}</Cell>} */}
            {(columnKey) => <Cell>{renderCell(columnKey, item)}</Cell>}
          </Row>
        )}
      </TableBody>
    </TableView>
  );
}

BidirectionalDnDTableView.defaultProps = {
  // rows: [],
  // overflowMode: "",
  // loadingState: "",
  // selectionMode: "",
  // onSelectionChange: null,
  // density: "",
  // disallowEmptySelection: null,
  // selectedKeys: [],
  disabledKeys: [],
  // onAction: null,
  // onLoadMore: null,
  // allowDragAndDrop: false,
};
BidirectionalDnDTableView.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
      ])
    )
  ).isRequired,
  renderCell: PropTypes.func.isRequired,
  disabledKeys: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
    ),
    PropTypes.any,
  ]),
};
