import {
  Cell,
  Column,
  Content,
  Heading,
  IllustratedMessage,
  Row,
  TableBody,
  TableHeader,
  TableView,
  useDragAndDrop,
  useListData,
} from "@adobe/react-spectrum";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import PropTypes from "prop-types";
import React from "react";

export default function Table({
  columns,
  rows,
  renderCell,
  overflowMode,
  loadingState,
  selectionMode,
  onSelectionChange,
  selectedKeys,
  density,
  disallowEmptySelection = false,
  disabledKeys,
  onAction,
  onLoadMore,
  allowDragAndDrop,
}) {
  function renderEmptyState() {
    return (
      <IllustratedMessage>
        <NotFound />
        <Heading>No results</Heading>
        <Content>No results found</Content>
      </IllustratedMessage>
    );
  }

  const dragAndDropList = useListData({
    initialItems: rows,
  });

  const { dragAndDropHooks } = useDragAndDrop({
    acceptedDragTypes: ["custom-app-type-bidirectional"],
    // Only allow move operations
    getAllowedDropOperations: () => ["move"],
    getItems(keys) {
      return [...keys].map((key) => {
        const item = dragAndDropList.getItem(key);
        // Setup the drag types and associated info for each dragged item.
        return {
          "custom-app-type-bidirectional": JSON.stringify(item),
          "text/plain": item.name,
        };
      });
    },
    onInsert: async (e) => {
      const { items, target } = e;
      const processedItems = await Promise.all(
        items.map(async (item) =>
          JSON.parse(await item.getText("custom-app-type-bidirectional"))
        )
      );
      if (target.dropPosition === "before") {
        dragAndDropList.insertBefore(target.key, ...processedItems);
      } else if (target.dropPosition === "after") {
        dragAndDropList.insertAfter(target.key, ...processedItems);
      }
    },
    onReorder: async (e) => {
      const { keys, target } = e;

      if (target.dropPosition === "before") {
        dragAndDropList.moveBefore(target.key, [...keys]);
      } else if (target.dropPosition === "after") {
        dragAndDropList.moveAfter(target.key, [...keys]);
      }
    },
    onRootDrop: async (e) => {
      const { items } = e;
      const processedItems = await Promise.all(
        items.map(async (item) =>
          JSON.parse(await item.getText("custom-app-type-bidirectional"))
        )
      );
      dragAndDropList.append(...processedItems);
    },
    onDragEnd: (e) => {
      const { dropOperation, keys, isInternal } = e;
      // Only remove the dragged items if they aren't dropped inside the source list
      if (dropOperation === "move" && !isInternal) {
        dragAndDropList.remove(...keys);
      }
    },
  });

  return (
    <TableView
      flex
      aria-label="CAM table with dynamic content"
      marginTop="1rem"
      renderEmptyState={() => renderEmptyState()}
      overflowMode={overflowMode || "wrap"}
      selectionMode={selectionMode || "none"}
      onSelectionChange={onSelectionChange}
      selectedKeys={selectedKeys}
      density={density}
      disallowEmptySelection={disallowEmptySelection}
      disabledKeys={disabledKeys}
      onAction={onAction}
      dragAndDropHooks={allowDragAndDrop ? dragAndDropHooks : null}
    >
      <TableHeader columns={columns}>
        {(column) => (
          <Column
            key={column.uid}
            minWidth={column?.minWidth}
            maxWidth={column?.maxWidth}
            defaultWidth={column?.defaultWidth}
            allowsResizing
          >
            {column.name}
          </Column>
        )}
      </TableHeader>
      <TableBody
        items={allowDragAndDrop ? dragAndDropList.items : rows}
        loadingState={loadingState}
        onLoadMore={onLoadMore}
      >
        {(item) => (
          <Row
            key={
              item.id ||
              item.requestId ||
              item.camid ||
              item.relationship_id ||
              item.org_entity_id
            }
          >
            {(columnKey) => <Cell>{renderCell(columnKey, item)}</Cell>}
          </Row>
        )}
      </TableBody>
    </TableView>
  );
}

Table.defaultProps = {
  rows: [],
  overflowMode: "",
  loadingState: "",
  selectionMode: "",
  onSelectionChange: null,
  density: "",
  disallowEmptySelection: null,
  selectedKeys: [],
  disabledKeys: [],
  onAction: null,
  onLoadMore: null,
  allowDragAndDrop: false,
};
Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
      ])
    )
  ),
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ).isRequired,
  renderCell: PropTypes.func.isRequired,
  overflowMode: PropTypes.string,
  loadingState: PropTypes.string,
  selectionMode: PropTypes.string,
  onSelectionChange: PropTypes.func,
  density: PropTypes.string,
  disallowEmptySelection: PropTypes.bool,
  selectedKeys: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
    ),
    PropTypes.any,
  ]),
  disabledKeys: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
    ),
    PropTypes.any,
  ]),
  onAction: PropTypes.func,
  onLoadMore: PropTypes.func,
  allowDragAndDrop: PropTypes.bool,
};
