import {
  Button,
  Flex,
  View,
  Text,
  Dialog,
  Heading,
  Content,
  useDialogContainer,
  ButtonGroup,
  DialogContainer,
  AlertDialog,
  TextArea,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import PropTypes from "prop-types";
import Table from "../../Common/Table";
import { workflowApi } from "../../../api/workflowApi";

export default function ViewTaskDetailsDialog({
  taskId,
  wfId,
  canApprove,
  handleApproval,
  handleRejection,
}) {
  const dialog = useDialogContainer();
  const [loadingState, setLoadingState] = useState("");
  const [oldState, setOldState] = useState([]);
  const [newState, setNewState] = useState([]);
  const [colInfo, setColInfo] = useState();
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const { authState } = useOktaAuth();

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    if (taskId !== "" && wfId !== "") {
      workflowApi
        .fetchWorkflowTaskDetails(
          {
            task_id: taskId,
            wf_id: wfId,
          },
          authState.accessToken
        )
        .then((data) => {
          setLoadingState("loading");

          setOldState([{ ...data.from_state, id: 1 }]);
          setNewState([{ ...data.to_state, id: 1 }]);

          const dynamicColumns = [];
          const objToIterate = data.from_state;
          Object.keys(objToIterate).forEach((attr) => {
            dynamicColumns.push({
              uid: attr,
              name:
                attr.charAt(0).toUpperCase() +
                attr.slice(1).replaceAll("_", " "),
            });
          });
          setColInfo(dynamicColumns);
          setLoadingState("");
        })
        .catch((e) => handleError(e));
    }
  }, [taskId]);

  const renderNewStateCell = (colKey, row) => {
    let cellStyle;
    if (row[colKey] !== oldState[0][colKey]) cellStyle = { color: "green" };
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
      </span>
    );
  };

  const renderOldStateCell = (colKey, row) => (
    <span // eslint-disable-line jsx-a11y/no-static-element-interactions
      style={{ cursor: "text", WebkitUserSelect: "text" }}
      onPointerDown={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Text>{row[colKey]}</Text>
    </span>
  );

  return (
    <Dialog width="60rem">
      <Content>
        <Flex direction="column">
          <View>
            <Heading level={3}>
              Workflow Id:{" "}
              <Text UNSAFE_style={{ fontWeight: "normal" }}>{wfId}</Text>
            </Heading>
            <Heading level={3}>
              Task Id:{" "}
              <Text UNSAFE_style={{ fontWeight: "normal" }}>{taskId}</Text>
            </Heading>
          </View>

          {colInfo && (
            <Flex
              direction="column"
              gap="size-125"
              margin="size-300"
              UNSAFE_style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
              UNSAFE_className="stepper-box"
            >
              <Table
                columns={colInfo}
                rows={oldState}
                selectionMode="none"
                renderCell={renderOldStateCell}
                density="compact"
                loadingState={loadingState}
              />
              <Table
                columns={colInfo}
                rows={newState}
                selectionMode="none"
                renderCell={renderNewStateCell}
                density="compact"
                loadingState={loadingState}
              />
            </Flex>
          )}

          <Flex alignItems="end" marginStart="auto">
            <ButtonGroup>
              {canApprove && (
                <>
                  <Button
                    variant="secondary"
                    UNSAFE_style={{ color: "green" }}
                    onPress={() => {
                      handleApproval([taskId]);
                      dialog.dismiss();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="secondary"
                    UNSAFE_style={{ color: "red" }}
                    onPress={() => setRejectionDialog(true)}
                  >
                    Reject
                  </Button>
                </>
              )}
              <Button variant="secondary" onPress={dialog.dismiss}>
                Close
              </Button>
            </ButtonGroup>
          </Flex>
        </Flex>
      </Content>
      <DialogContainer>
        {rejectionDialog && (
          <AlertDialog
            title="Reject request"
            variant="destructive"
            primaryActionLabel="Yes"
            cancelLabel="No"
            onPrimaryAction={() => {
              handleRejection([taskId], rejectionReason);
              dialog.dismiss();
            }}
            onCancel={() => setRejectionDialog(false)}
            zIndex={2}
          >
            <Flex direction="column" gap="size-150">
              <Text>
                Are you sure you want to reject this request? Please confirm by
                providing reason for rejecting the request.
              </Text>
              <TextArea
                label="Reason"
                width="100%"
                value={rejectionReason}
                onChange={(value) => setRejectionReason(value)}
              />
            </Flex>
          </AlertDialog>
        )}
      </DialogContainer>
    </Dialog>
  );
}

ViewTaskDetailsDialog.propTypes = {
  taskId: PropTypes.string.isRequired,
  wfId: PropTypes.string.isRequired,
  canApprove: PropTypes.bool.isRequired,
  handleApproval: PropTypes.func.isRequired,
  handleRejection: PropTypes.func.isRequired,
};
