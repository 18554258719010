export const StandardizedAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "CAM ID",
      key: "camid",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Street",
      key: "street",
    },
    {
      title: "Street Sup",
      key: "street_sup",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Postal Code",
      key: "postalcode",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "Domain",
      key: "domain",
    },
    {
      title: "Duns Number",
      key: "duns_number",
    },
    {
      title: "Global Parent ID",
      key: "gtm_global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "gtm_global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "gtm_global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "gtm_global_sub_name",
    },
    {
      title: "Created At",
      key: "sliding_created_at",
    },
    {
      title: "Last Updated",
      key: "sliding_last_updated",
    },
  ];

  return COLUMNS;
};

export const OrgEntityAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "ORG ID",
      key: "org_entity_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Street",
      key: "street",
    },
    {
      title: "Street Sup",
      key: "street_sup",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Postal Code",
      key: "postalcode",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "Domain",
      key: "domain",
    },
    {
      title: "Duns Number",
      key: "duns_number",
    },
    {
      title: "Global Parent ID",
      key: "gtm_global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "gtm_global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "gtm_global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "gtm_global_sub_name",
    },
    {
      title: "Created At",
      key: "sliding_created_at",
    },
    {
      title: "Last Updated",
      key: "sliding_last_updated",
    },
  ];

  return COLUMNS;
};

export const SourceSystemAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "Src Account ID",
      key: "account_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Address",
      key: "address",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Account Owner",
      key: "account_owner",
    },
    {
      title: "Industry",
      key: "crm_industry",
    },
    {
      title: "DX Sales Major Segment",
      key: "dx_sales_major_segment",
    },
    {
      title: "DX Sales Minor Segment",
      key: "dx_sales_minor_segment",
    },
    {
      title: "Dme Sales Major Segment",
      key: "dme_sales_major_segment",
    },
    {
      title: "Global Parent ID",
      key: "global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "global_sub_name",
    },
    {
      title: "Approval Status",
      key: "approval_status",
    },
    // {
    //   title: "Duplicate",
    //   key: "dup_flag",
    // },
    {
      title: "Created At",
      key: "sliding_created_at",
    },
    {
      title: "Last Updated",
      key: "sliding_last_updated",
    },
  ];

  return COLUMNS;
};

export const HMSSourceSystemAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "Src Account ID",
      key: "account_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Address",
      key: "address",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Account Owner",
      key: "account_owner",
    },
    {
      title: "Industry",
      key: "crm_industry",
    },
    {
      title: "DX Sales Major Segment",
      key: "dx_sales_major_segment",
    },
    {
      title: "DX Sales Minor Segment",
      key: "dx_sales_minor_segment",
    },
    {
      title: "Dme Sales Major Segment",
      key: "dme_sales_major_segment",
    },
    {
      title: "Global Parent ID",
      key: "global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "global_sub_name",
    },
    {
      title: "Approval Status",
      key: "approval_status",
    },
    // {
    //   title: "Duplicate",
    //   key: "dup_flag",
    // },
    {
      title: "Created At",
      key: "createddatetime",
    },
    {
      title: "Last Updated",
      key: "modifieddatetime",
    },
  ];

  return COLUMNS;
};

export const AddressLabel = () => {
  const COLUMNS = [
    {
      title: "Street",
      key: "street",
    },
    {
      title: "Street Sup",
      key: "street_sup",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Postal Code",
      key: "postalcode",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
  ];

  return COLUMNS;
};

export const EAMSourceSystemAccountLabel = () => {
  const COLUMNS = [
    {
      title: "Account ID",
      key: "account_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Address",
      key: "address",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Account Owner",
      key: "account_owner",
    },
    {
      title: "Industry",
      key: "crm_industry",
    },
    {
      title: "DX Sales Major Segment",
      key: "dx_sales_major_segment",
    },
    {
      title: "DX Sales Minor Segment",
      key: "dx_sales_minor_segment",
    },
    {
      title: "Global Parent ID",
      key: "global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "global_sub_name",
    },
    {
      title: "Duplicate",
      key: "dup_flag",
    },
    {
      title: "Approval Status",
      key: "approval_status",
    },
    {
      title: "Dme Sales Major Segment",
      key: "dme_sales_major_segment",
    },
  ];

  return COLUMNS;
};

export const EnrichedAttributesLabel = () => {
  const COLUMNS = [
    {
      title: "DUNS",
      key: "duns",
    },
    {
      title: "BUSINESS NAME",
      key: "business_name",
    },
    {
      title: "STREET ADDRESS",
      key: "street_address",
    },
    {
      title: "STREET ADDRESS 2",
      key: "street_address2",
    },
    {
      title: "CITY",
      key: "city",
    },
    {
      title: "STATE",
      key: "state",
    },
    {
      title: "STATE ABBREVIATION",
      key: "state_abbr",
    },
    {
      title: "NATIONAL IDENTIFICATION NUMBER",
      key: "duns",
    },
    {
      title: "TELEPHONE NUMBER",
      key: "duns",
    },
    {
      title: "CHIEF EXECUTIVE OFFICER NAME",
      key: "duns",
    },
    {
      title: "CHIEF EXECUTIVE OFFICER TITLE",
      key: "duns",
    },
    {
      title: "LINE OF BUSINESS",
      key: "duns",
    },
    {
      title: "YEAR STARTED",
      key: "duns",
    },
    {
      title: "PREVIOUS D-U-N-S NUMBER",
      key: "duns",
    },
    {
      title: "FULL REPORT DATE",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT D-U-N-S NUMBER",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT BUSINESS NAME",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT STREET ADDRES",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT CITY NAME",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT STATE/PROVINC",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT COUNTRY NAME",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT D&B STATE/PROVINC",
      key: "duns",
    },
    {
      title: "HEADQUARTER/PARENT POSTAL CODE",
      key: "duns",
    },
    {
      title: "DOMESTIC ULTIMATE D-U-N-S NUMBER",
      key: "duns",
    },
    {
      title: "DOMESTIC ULTIMATE BUSINESS NAME",
      key: "duns",
    },
    {
      title: "DOMESTIC ULTIMATE STREET ADDRESS",
      key: "duns",
    },
    {
      title: "DOMESTIC ULTIMATE CITY NAME",
      key: "duns",
    },
    {
      title: "DOMESTIC ULTIMATE STATE/PROVINCE",
      key: "duns",
    },
    {
      title: "DOMESTIC ULTIMATE D&B STATE/PROVINCE",
      key: "duns",
    },
    {
      title: "DOMESTIC ULTIMATE POSTAL CODE",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE D-U-N-S NUMBER",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE BUSINESS NAME",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE STREET ADDRESS",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE CITY NAME",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE STATE/PROVINCE N",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE COUNTRY NAME",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE STATE/PROVINCE A",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE D&B COUNTRY CODE",
      key: "duns",
    },
    {
      title: "GLOBAL ULTIMATE POSTAL CODE",
      key: "duns",
    },
    {
      title: "URL DOMAIN NAME 1",
      key: "duns",
    },
  ];

  return COLUMNS;
};

export const GoldenRecordAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "ORG ID",
      key: "org_entity_id",
    },
    {
      title: "Account Name",
      key: "org_name_1",
    },
    {
      title: "Street",
      key: "street_1",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Postal Code",
      key: "postalcode",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "Domain",
      key: "domain",
    },
    {
      title: "Website",
      key: "website",
    },
    {
      title: "Duns Number",
      key: "duns_number",
    },
    // {
    //   title: "Global Parent ID",
    //   key: "gtm_global_parent_id",
    // },
    // {
    //   title: "Global Parent Name",
    //   key: "gtm_global_parent_name",
    // },
    // {
    //   title: "Global Sub ID",
    //   key: "gtm_global_sub_id",
    // },
    // {
    //   title: "Global Sub Name",
    //   key: "gtm_global_sub_name",
    // },
    {
      title: "Created",
      key: "createddatetime",
    },
    {
      title: "Created By",
      key: "createdby",
    },
    {
      title: "Last Updated",
      key: "modifieddatetime",
    },
    {
      title: "Last Updated By",
      key: "modifiedby",
    },
  ];

  return COLUMNS;
};

export const GoldenRecordSourceSystemAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "Src Account ID",
      key: "account_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Address",
      key: "address",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Account Owner",
      key: "account_owner",
    },
    {
      title: "Industry",
      key: "crm_industry",
    },
    {
      title: "DX Sales Major Segment",
      key: "dx_sales_major_segment",
    },
    {
      title: "DX Sales Minor Segment",
      key: "dx_sales_minor_segment",
    },
    {
      title: "Dme Sales Major Segment",
      key: "dme_sales_major_segment",
    },
    {
      title: "Global Parent ID",
      key: "global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "global_sub_name",
    },
    {
      title: "Approval Status",
      key: "approval_status",
    },
    // {
    //   title: "Duplicate",
    //   key: "dup_flag",
    // },
    {
      title: "Created",
      key: "createddatetime",
    },
    {
      title: "Created By",
      key: "createdby",
    },
    {
      title: "Last Updated",
      key: "modifieddatetime",
    },
    {
      title: "Last Updated By",
      key: "modifiedby",
    },
  ];

  return COLUMNS;
};

export const LAGGroupLabel = () => {
  const COLUMNS = [
    {
      title: "DOCID",
      key: "id",
    },
    {
      title: "LAGID",
      key: "lag_id",
    },
    {
      title: "CAMID",
      key: "cam_id",
    },
    {
      title: "ECCID",
      key: "ecc_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "Domain",
      key: "domain",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Group",
      key: "group_key",
    },
    {
      title: "Related Group",
      key: "related_group_key",
    },
    {
      title: "Description",
      key: "group_description",
    },
    {
      title: "IMS Org Name",
      key: "ims_org_name",
    },
    {
      title: "IMS Org ID",
      key: "ims_org_id",
    },
  ];

  return COLUMNS;
};
