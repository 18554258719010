import { Flex, Heading, Text, View } from "@adobe/react-spectrum";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Card from "../../components/Common/Card/Card";
import {
  AccountMappingPath,
  DashboardPath,
  ExternalAccountMappingPath,
  LookupLogicalAccountGroupingPath,
  LookupHierarchyManagementPath,
} from "../../constants/Routes";

const Home = () => {
  const history = useHistory();

  const cardContent = (content) => (
    <Flex height="8rem" direction="column" justifyContent="space-between">
      <Flex width="size-2400" wrap="wrap">
        <Text>{content}</Text>
      </Flex>
      <Flex justifyContent="end" alignItems="end">
        <View
          borderColor="gray-300"
          borderRadius="medium"
          borderWidth="thick"
          padding="size-100"
        >
          <Heading level={4}>Get Started</Heading>
        </View>
      </Flex>
    </Flex>
  );
  return (
    <Flex direction="column" gap="size-1000">
      <Flex direction="column">
        <Flex justifyContent="center" alignItems="center">
          <View padding="2rem">
            <Heading
              level={2}
              UNSAFE_style={{
                fontFamily: "adobe-clean-serif",
              }}
            >
              Mission
            </Heading>
          </View>
        </Flex>
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Text>
            We bring value to Business by providing real time access to
            standardized B2B account info, associated enrichment and insightful
          </Text>
          <Text>
            relationships across business functions including marketing, sales,
            order management, commerce and customer service.
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent="center" alignItems="center" gap="size-200">
        <Card
          title="Cross System Account"
          content={cardContent(
            "View and update the CAM ID mapping with the source accounts"
          )}
          handleOnPress={() => history.push(AccountMappingPath)}
        />
        <Card
          title="Dashboards"
          content={cardContent("Consolidate Multi-CRM Sync dashboards")}
          handleOnPress={() => history.push(DashboardPath)}
        />
        <Card
          title="External Account Management"
          content={cardContent(
            "View and update external account mapping by remapping external accounts or splitting existing mappings"
          )}
          handleOnPress={() => history.push(ExternalAccountMappingPath)}
        />
        <Card
          title="Logical Account Grouping"
          content={cardContent(
            "View and update logical account grouping by creating, extending, or overriding groups"
          )}
          handleOnPress={() => history.push(LookupLogicalAccountGroupingPath)}
        />
        <Card
          title="Hierarchy Management"
          content={cardContent("View and update hierarchy details")}
          handleOnPress={() => history.push(LookupHierarchyManagementPath)}
        />
      </Flex>
    </Flex>
  );
};

export default Home;
